import {
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useState } from "react";
import Search_Icon from "../../assets/images/Search_Icon.svg";

export default function RoleMangerList({
  roleManager,
  setAssignedManager,
  handleSearchInput,
}) {
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRadioChange = (value:any, index) => {
    setSelectedRow(index);
    if (value) {
      setAssignedManager(value);
    }
  };

  return (
    <div className="container p-4">
      <div className="flex justify-center items-center mb-3">
        <p>Select from list to assign</p>
        <div className="search-container ml-auto">
          <input
            type="text"
            placeholder="Search by name, role"
            className="search-input-farmar-modal w-full"
            onChange={(e) => {
              handleSearchInput(roleManager, e.target.value);
            }}
          />
          <div className="search-icon">
            <img src={Search_Icon} alt="Search_Icon" />
          </div>
        </div>
      </div>
      <Table aria-label="simple table" className="border">
        <TableHead>
          <TableRow className="bg-[#E5F5F6] text-nowrap">
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {roleManager?.map((user, index) => (
            <TableRow key={index} className="border-0 text-nowrap hover:bg-gray-100 cursor-pointer" onClick={() => handleRadioChange(user?.userId,index)}>
              <TableCell className="border-0">
                <Radio
                  name="roleManager"
                  size="small"
                  checked={selectedRow === index}
                  onChange={(event) => handleRadioChange(event.target.value, index)}
                  value={user?.userId}
                />
              </TableCell>
              <TableCell>{user?.name}</TableCell>
              <TableCell>{user?.roles[0]?.roleName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

import * as React from "react";
import Dialog from "@mui/material/Dialog";

export default function AlertDialog({ closeDialog, handleClose, open }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25%"
          }
        }}
      >
        <h4 className="p-4">
          There are some unsaved data, Do you really want to discard changes?
        </h4>
       <div className="flex ml-auto gap-2 p-4">
       <button
            className="border border-gray-300 px-4 py-1 rounded"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="border border-gray-300 px-4 py-1 rounded bg-[#009CA7] text-white"
            onClick={closeDialog}
          >
            Discard
          </button>
       </div>
      </Dialog>
    </React.Fragment>
  );
}

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import basline_icon from "../../assets/images/bseline_Icon.svg";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import upload from "../../assets/images/upload.svg";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import StorageHelper from "../../Utility/StorageHelper";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";
import AlertDialog from "../Users/AlertDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  blankCell: {
    backgroundColor: "#ff4d4d",
  },

  table: {
    minWidth: 650,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  tableContainer: {
    borderSpacing: "15px",
  },
  tableRow: {
    borderBottom: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  editIcon: {
    color: "gray",
  },
}));
interface AddUsersProps {
  closeDialog: () => void;
  bulkUpload: boolean;
  users: any;
}
interface Option {
  label: string;
  value: string;
}
interface Payload {
  farmer_Type: string;
  farmer_id: string;
}
const Bulk_Update: React.FC = (props) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const [farmerIDcheck, setFarmerID] = useState<any>([]);
  const [fileName, setFileName] = useState<string>("");
  const [isImportAwaayClick, setImportAwayClicked] = useState(false);
  const [nextButnClicked, setNextButtonClicked] = useState(false);
  const [currentState, setCurrentState] = useState(/* initial state */);
  const [previousState, setPreviousState] = useState(undefined);
  const [sliderValue, setSliderValue] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [dataImporting, setDataImporting] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [fileData, setFileData] = useState<any>(null);
  const [nextData, setNextData] = useState<any>(null);

  const [originalTableBody, setOriginalTableBody] = useState<any>(null);

  const handleSliderChange = () => {
    const nextValue = sliderValue === 0 ? 1 : 0;
    setSliderValue(nextValue);
  };
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleOpen = () => {
    setOpenAlert(true);
  };

  useEffect(() => {
    setPreviousState(currentState);
  }, [currentState]);

  const handlePrevClick = () => {
    if (isImportAwaayClick) {
      setImportAwayClicked(false);
      setNextButtonClicked(false);
    } else if (nextButnClicked) {
      setNextButtonClicked(false);
      setImportAwayClicked(false);
      // setFileUploaded(false);
      // setFileData(false);
    } else if (fileData) {
      setNextData(false);
    }
  };
  const handleRemoveFile = () => {
    setFile(null);
    setFileName("");
    setFileUploaded(false);
    setFileData(null);
    // Reset file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const nextButtonClicked = async () => {
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        const contents = e.target?.result as string;
        const workbook = XLSX.read(contents, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        setFileData(data);

        setNextData(data);
        // checkFarmerIDValidation();

        setSliderValue(0.5);
      };
      setNextButtonClicked(true);

      const fileInput = document.getElementById("fileInput");
      if (fileInput instanceof HTMLInputElement && fileInput.files?.length) {
        reader.readAsBinaryString(fileInput.files[0]);
      }
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!nextButtonClicked) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const contents = e.target?.result as string;
        const workbook = XLSX.read(contents, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data: any = XLSX.utils.sheet_to_json(sheet);
        const expectedHeaders = [
          "FarmerID",
          "Farmer Type(baseline or project)",
        ];

        const uploadedHeaders = Object.keys(data[0]);
        const matchingHeadersCount = expectedHeaders.reduce((count, header) => {
          if (uploadedHeaders.includes(header)) {
            return count + 1;
          }
          return count;
        }, 0);

        if (matchingHeadersCount < 1) {
          setFile(null);
          const message = "Invalid file. Please try again !";
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            style: {
              backgroundColor: "#ff4d4d",
              color: "white",
            },
            autoClose: 2000,
            toastId: "customErrorToast",
          });
          const fileInput = document.getElementById("fileInput");
          if (fileInput !== null && fileInput !== undefined) {
            (fileInput as HTMLInputElement).value = "";
          }

          return;
        }
        setSliderValue(0.5);
      } catch (error) {
        setFile(null);
        const message = "Invalid file. Please try again !";
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#ff4d4d",
            color: "white",
          },
          autoClose: 2000,
          toastId: "customErrorToast",
        });
        const fileInput = document.getElementById("fileInput");
        if (fileInput !== null && fileInput !== undefined) {
          (fileInput as HTMLInputElement).value = "";
        }

        return;
      }
    };

    reader.onerror = function () {
      const message = "Failed to read the file. Please try again";
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        style: {
          backgroundColor: "#ff4d4d",
          color: "white",
        },
        autoClose: 3000,
        toastId: "customErrorToast",
      });
    };

    setFile(file);
    const fileName = file.name;
    setFileName(fileName);
    setFileUploaded(true);
    reader.readAsBinaryString(file);
  };

  const handleClickOpenBulkUpload = (bulkUpload) => {
    setOpen(true);
  };

  const closeDialog = () => {
    if (!fileUploaded) {
      setOpen(false);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setOpenAlert(false);
    setFileUploaded(false);
    setNextButtonClicked(false);
    setImportAwayClicked(false);
  };
  const handleClose = () => {
    setOpenAlert(false);
  };
  const handleSwitchTestUser = (event) => {
    setImportAwayClicked(event.target.checked);
  };

  useEffect(() => {
    if (nextButnClicked && fileData) {
      const checkFarmerIDValidation = async () => {
        const FarmerIDs: number[] = [];

        fileData?.forEach((userData: { [key: string]: any }) => {
          const farmerID = userData["FarmerID"];
          // Check if the farmerID is not null and contains only digits
          if (farmerID !== null && /^\d{1,9}$/.test(farmerID)) {
            FarmerIDs.push(parseInt(farmerID, 10)); // Parse and push the number
          }
        });

        try {
          setDataLoading(true);
          const authData = JSON.parse(localStorage.getItem("auth") || "{}");
          if (authData) {
            const authHeader = {
              JWTToken: authData?.jwtToken,
              userId: authData?.userId,
            };
            const params = {
              farmer_id: FarmerIDs,
            };

            const response: any = await FnAuthenticatedRequest(
              "POST",
              "api/checkFarmerIDValidation",
              authHeader,
              params
            );

            const formattedData = response?.data;

            if (response?.success) {
              setTimeout(() => {
                setDataLoading(false);
              }, 800);

              setFarmerID(formattedData);
            } else {
              setTimeout(() => {
                setDataLoading(false);
              }, 800);
            }
          }
        } catch (error: any) {
          setTimeout(() => {
            setDataLoading(false);
          }, 800);
          console.error("Error validating data:", error);
        }
      };

      checkFarmerIDValidation();
    }
  }, [nextButnClicked && fileData]);

  function isValidFarmerID(userData, farmerIDcheck, fileData, currentIndex) {
    if (typeof userData !== "number" || userData?.toString().length > 9) {
      return false;
    }

    const isDigitsOnly = /^\d+$/.test(userData.toString());

    if (!isDigitsOnly) {
      return false;
    }
    for (let i = 0; i < fileData?.length; i++) {
      if (i !== currentIndex && fileData[i]["FarmerID"] === userData) {
        return false;
      }
    }

    const isMatch = farmerIDcheck?.includes(userData.toString().trim());

    return !isMatch;
  }

  function isValidFarmerType(userData) {
    if (typeof userData !== "string") {
      return false;
    }

    const trimmedData = userData?.toLowerCase()?.trim();

    return trimmedData === "baseline" || trimmedData === "project";
  }

  const filterRows = (fileData, includeUnsatisfiedData = false) => {
    if (fileData && Array.isArray(fileData)) {
      return fileData.filter((userData, index) => {
        const isSatisfied =
          userData &&
          isValidFarmerID(
            userData["FarmerID"],
            farmerIDcheck,
            fileData,
            index
          ) &&
          isValidFarmerType(userData["Farmer Type(baseline or project)"]);

        return includeUnsatisfiedData ? !isSatisfied : isSatisfied;
      });
    }
    return [];
  };

  const allRows = filterRows(fileData, true);
  const satisfiedRows = allRows.filter((row) => row.isSatisfied);
  const unsatisfiedRows = filterRows(fileData, true);
  const filteredRows = filterRows(fileData, false);

  useEffect(() => {
    const originalTableBody = fileData ? (
      <TableBody>
        {fileData.map((userData, index) => (
          <TableRow key={index} className="border-0 text-nowrap">
            <TableCell
              className={
                isValidFarmerID(
                  userData["FarmerID"],
                  farmerIDcheck,
                  fileData,
                  index
                )
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["FarmerID"]}
            </TableCell>
            <TableCell
              className={
                isValidFarmerType(userData["Farmer Type(baseline or project)"])
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["Farmer Type(baseline or project)"]}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    ) : null;

    setOriginalTableBody(originalTableBody);
  }, [fileData, farmerIDcheck]);

  const importAwayTableBody = fileData ? (
    <TableBody>
      {/* Render rows with errors */}
      {fileData.map((userData, index) => {
        // Check for errors in each cell
        const isIDError = !isValidFarmerID(
          userData["FarmerID"],
          farmerIDcheck,
          fileData,
          index
        );
        const isTypeError = !isValidFarmerType(
          userData["Farmer Type(baseline or project)"]
        );
  
        // Determine if the entire row should be styled as an error
        const rowHasError = isIDError || isTypeError;
  
        // If the row has an error, render it
        if (rowHasError) {
          return (
            <TableRow
              key={index}
              className={`border-0 text-nowrap`}
            >
              {/* Conditionally apply styling to each cell based on errors */}
              <TableCell className={isIDError ? classes.blankCell : ""}>
                {userData["FarmerID"]}
              </TableCell>
              <TableCell className={isTypeError ? classes.blankCell : ""}>
                {userData["Farmer Type(baseline or project)"]}
              </TableCell>
            </TableRow>
          );
        }
        return null; // Return null for rows without errors to be handled later
      })}
  
      {/* Render rows without errors */}
      {fileData.map((userData, index) => {
        // Check for errors in each cell
        const isIDError = !isValidFarmerID(
          userData["FarmerID"],
          farmerIDcheck,
          fileData,
          index
        );
        const isTypeError = !isValidFarmerType(
          userData["Farmer Type(baseline or project)"]
        );
  
        // Determine if the entire row should be styled as an error
        const rowHasError = isIDError || isTypeError;
  
        // If the row has no error, render it
        if (!rowHasError) {
          return (
            <TableRow key={index} className="border-0 text-nowrap">
              {/* Render cells without any special styling */}
              <TableCell>{userData["FarmerID"]}</TableCell>
              <TableCell>{userData["Farmer Type(baseline or project)"]}</TableCell>
            </TableRow>
          );
        }
        return null; // Skip rendering rows with errors (already rendered above)
      })}
    </TableBody>
  ) : null;
  

  const handleUpdateFarmerType = async (e: any) => {
    e.preventDefault();
    try {
      setDataImporting(true);

      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData?.jwtToken,
        userId: authData?.userId,
      };
      const filteredData = filterRows(fileData, false);

      const payloads: Payload[] = [];

      for (let userData of filteredData) {
        const payload: Payload = {
          farmer_Type:
            userData["Farmer Type(baseline or project)"]?.toLowerCase(),
          farmer_id: userData["FarmerID"],
        };

        payloads.push(payload);
      }

      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/FarmerTypeUpdate",
        authHeader,
        payloads
      );
      if (response?.success) {
        setDataImporting(true);
        setTimeout(() => {
          window.location.reload();
        }, 1200);

        const entryCount = payloads?.length;
        const message = `✓ ${entryCount} Entry Import${
          entryCount !== 1 ? "s" : ""
        } Successful`;
        toast?.success(message, {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#258750",
            color: "white",
          },
          autoClose: 2000,
          toastId: "customErrorToast",
        });
        setTimeout(() => {
          setDataImporting(false);
        }, 1600);
      } else {
        toast.error(response?.error || "Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          toastId: "customErrorToast",
        });
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        toastId: "customErrorToast",
      });
    }
  };

  const downloadExcel = (workbook, fileName) => {
    try {
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      console.error(error);
    }
  };

  const generateExcelFile = (rows) => {
    const headers = Object.keys(rows[0]);

    const data = rows.map((row) => Object.values(row));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    downloadExcel(wb, "Baseline_ErrorData");
  };

  return (
    <>
      {!dataLoading ? (
        <>
          {open ? (
            <div className="container h-screen min-w-full overflow-hidden ">
              <div className="block sm:flex items-center min-w-full">
                <ArrowBackIcon
                  //   onClick={handleClose}
                  onClick={() => {
                    if (isImportAwaayClick || fileUploaded) {
                      handleOpen();
                    } else {
                      closeDialog();
                    }
                  }}
                  className="cursor-pointer transition-all hover:text-[#009CA7] active:text-[#007a80] ml-5"
                />
                <div className=" sm-flex items-center ml-3 px-3 py-3">
                  <h1 className="text-xl font-bold">Bulk Update</h1>
                  <h1 className="text-l ">Baseline/Project Farmer </h1>
                </div>
              </div>
              <hr className="mt-0 border-gray-200" />
              <div className="container screen-h min-w-full overflow-hidden">
                <div className="flex items-center justify-center mt-6">
                  <div
                    className={`w-6 h-6 rounded-full flex items-center justify-center mr-0 bg-[#009CA7]`}
                  >
                    <span className="text-white">
                      {nextButnClicked ? (
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2074 0.333344L4.16659 6.19823L1.79242 3.90211L0.499919 5.15697L4.16659 8.71684L11.4999 1.5971L10.2074 0.333344Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        "1"
                      )}
                    </span>
                  </div>

                  <div className="h-1 relative bg-gray-200 w-36">
                    <div
                      className={`absolute inset-y-0 ${
                        fileUploaded ? "bg-green-500" : "bg-transparent"
                      }`}
                      style={{
                        width: nextButnClicked
                          ? "100%"
                          : fileUploaded
                          ? "50%"
                          : "0%",
                        backgroundColor: fileUploaded
                          ? "#009CA7"
                          : "bg-transparent",
                      }}
                    />
                  </div>
                  <div
                    className={`w-6 h-6 rounded-full flex items-center justify-center ml-0 ${
                      nextButnClicked ? "bg-[#009CA7]" : "bg-gray-200"
                    }`}
                  >
                    <span
                      className={
                        nextButnClicked ? "text-white" : "text-[#838383]"
                      }
                    >
                      2
                    </span>
                  </div>
                </div>
                <div className="flex justify-center mt-2">
                  <label className="mr-32">Upload</label>
                  <label
                    className={` mr-0 ${
                      fileUploaded ? "text-[#000000]" : "text-[#838383]"
                    }`}
                  >
                    View
                  </label>
                </div>
                <br />
                {nextButnClicked ? (
                  <div className="h-[450px] overflow-auto min-w-full ">
                    <div className="block sm:flex items-center min-w-full">
                      <h1 className="text-sm ml-7 mr-auto mt-4">
                        Showing{" "}
                        {isImportAwaayClick
                          ? fileData?.length
                          : fileData
                          ? fileData?.length
                          : 0}{" "}
                        entries
                      </h1>
                      {unsatisfiedRows?.length != 0 &&
                        filteredRows?.length != 0 && (
                          <FormControlLabel
                            className="text-xs  mr-auto mt-4 "
                            control={
                              <Switch
                                className="-mr-2"
                                color="primary"
                                checked={isImportAwaayClick}
                                onChange={handleSwitchTestUser}
                              />
                            }
                            label={
                              <span
                                style={{ fontSize: "14px", color: "#1A1A1A" }}
                              >
                                Sort by errors at top{" "}
                              </span>
                            }
                          />
                        )}
                    </div>
                    <div className="mr-5 ml-5 pb-36">
                      <div className="container  py-1 m-2  mt-4  border-white bg-white  min-w-full">
                        <div className="flex items-center mt-2 ml-2">
                          {filteredRows?.length !== 0 && nextButnClicked && (
                            <div className={`flex items-center`}>
                              <div
                                className={`w-5 h-5 rounded-full bg-[#009CA7] flex items-center justify-center ml-3 mr-1`}
                              >
                                <svg
                                  width="12"
                                  height="9"
                                  viewBox="0 0 12 9"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.2074 0.333344L4.16659 6.19823L1.79242 3.90211L0.499919 5.15697L4.16659 8.71684L11.4999 1.5971L10.2074 0.333344Z"
                                    fill="white"
                                  />
                                </svg>
                              </div>
                              <span className="font-bold font-Open Sans size-14px">
                                <span className="text-[#009CA7] font-bold">
                                  {filteredRows?.length}{" "}
                                  {filteredRows?.length == 1
                                    ? "Entry"
                                    : "Entries"}
                                </span>{" "}
                                {filteredRows?.length == 1 ? "is" : "are"} ready
                                to be imported
                              </span>
                            </div>
                          )}
                          {(satisfiedRows?.length !== 0 &&
                            unsatisfiedRows?.length !== 0) ||
                            (satisfiedRows?.length === 0 &&
                              unsatisfiedRows?.length !== 0 && (
                                <div className={`flex items-center ml-4`}>
                                  {filteredRows?.length !== 0 &&
                                    unsatisfiedRows?.length !== 0 && (
                                      <svg
                                        className={` mr-4`}
                                        width="1"
                                        height="23"
                                        viewBox="0 0 1 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <line
                                          x1="0.5"
                                          y1="2.18558e-08"
                                          x2="0.499999"
                                          y2="23"
                                          stroke="#CCCCCC"
                                        />
                                      </svg>
                                    )}

                                  <svg
                                    className={` mr-1`}
                                    width="22"
                                    height="22"
                                    viewBox="0 0 22 22"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g clipPath="url(#clip0_10412_3513)">
                                      <path
                                        d="M0.916748 19.25H21.0834L11.0001 1.83331L0.916748 19.25ZM11.9167 16.5H10.0834V14.6666H11.9167V16.5ZM11.9167 12.8333H10.0834V9.16665H11.9167V12.8333Z"
                                        fill="#FF0000"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_10412_3513">
                                        <rect
                                          width="22"
                                          height="22"
                                          fill="white"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>

                                  <span className="font-bold  font-Open Sans size-14px">
                                    <span className="text-[#FF0000] font-bold">
                                      {unsatisfiedRows?.length}{" "}
                                      {unsatisfiedRows?.length == 1
                                        ? "Entry"
                                        : "Entries"}
                                    </span>{" "}
                                    {filteredRows?.length === 0 &&
                                    unsatisfiedRows?.length !== 0
                                      ? "contains error, fix error before you import."
                                      : "will be skipped if you import."}
                                    {filteredRows?.length !== 0 &&
                                      unsatisfiedRows?.length !== 0 && (
                                        <span
                                          className="text-[#009CA7] px-1 cursor-pointer underline"
                                          onClick={() => {
                                            generateExcelFile(unsatisfiedRows);
                                          }}
                                        >
                                          Download
                                        </span>
                                      )}
                                  </span>
                                </div>
                              ))}
                        </div>
                        {fileData?.length !== 0 && (
                          <div className="border border-gray-50 min-w-full mt-2 "></div>
                        )}
                        <div className="m-3 mr-5 ml-5 mt-3 border-2 border-gray-300 ">
                          <hr className=" border-gray-200" />
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow className="bg-[#E5F5F6] text-nowrap">
                                  <TableCell className={classes.bold}>
                                    Farmer_Id{" "}
                                  </TableCell>
                                  <TableCell className={classes.bold}>
                                    Type of Farmer{" "}
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              {isImportAwaayClick
                                ? importAwayTableBody
                                : originalTableBody}
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : fileUploaded ? (
                  <div className="mt-10">
                    <div className="flex justify-center mt-0">
                      <div className="border-2 border-dashed border-opacity-50 border-gray-400 bg-[#D3FFD8] flex flex-col justify-center items-center p-10">
                        <input
                          ref={fileInputRef}
                          type="file"
                          id="fileInput"
                          accept=".csv, .xlsx"
                          style={{ display: "none" }}
                          onChange={handleFileUpload}
                        />
                        {fileName && (
                          <h2>
                            {fileName}{" "}
                            <span
                              className="cursor-pointer text-2xl text-red-500 ml-2"
                              onClick={handleRemoveFile}
                            >
                              x
                            </span>
                          </h2>
                        )}
                        <h1
                          onClick={() => {
                            const fileInput = document.getElementById(
                              "fileInput"
                            ) as HTMLInputElement;
                            if (fileInput) {
                              const currentFile = fileInput.files
                                ? fileInput.files[0]
                                : null;
                              const currentFileName = currentFile
                                ? currentFile.name
                                : "";

                              fileInput.click();

                              fileInput.addEventListener("change", () => {
                                if (fileInput.files && fileInput.files[0]) {
                                  setFile(fileInput.files[0]);
                                  setFileName(fileInput.files[0].name);
                                } else {
                                  setFile(currentFile);
                                  setFileName(currentFileName);
                                }
                              });
                            }
                          }}
                          className="mt-4 cursor-pointer text-[#009CA7] font-semibold"
                        >
                          Replace file
                        </h1>
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-5">
                      <h1>
                        Use csv, xlsx format or{" "}
                        <a
                          href="/Baseline Upload Template.xlsx"
                          className="text-[#009CA7] font-bold"
                          download
                        >
                          Download Template
                        </a>
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="mt-10">
                    <div className="flex justify-center mt-0">
                      <div
                        className="border-2 border-dashed border-opacity-50 border-gray-300 bg-white flex flex-col justify-center items-center py-10 px-28"
                        onClick={() =>
                          document.getElementById("fileInput")?.click()
                        }
                      >
                        <input
                          type="file"
                          id="fileInput"
                          accept=".csv, .xlsx"
                          style={{ display: "none" }}
                          onChange={handleFileUpload}
                        />
                        <img src={upload} alt="Bulk Upload Image" />
                        <h1 className="mt-4" style={{ color: "#009CA7" }}>
                          Upload file
                        </h1>
                      </div>
                    </div>
                    <div className="flex flex-div justify-center items-center mt-5">
                      <h1>
                        Use csv, xlsx format or{" "}
                        <a
                          href="/Baseline Upload Template.xlsx"
                          download
                          className="text-[#009CA7] font-bold"
                        >
                          Download Template
                        </a>
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              <footer className="relative flex justify-center items-center ">
                <div className="fixed bottom-0 bg-white min-w-full  py-1.5">
                {(fileData && !nextButnClicked) ||
                (!fileUploaded && !nextButnClicked) || 
                (fileUploaded && !nextButnClicked) ? (
                  <div className="flex justify-center items-center  gap-96">
                    <button
                      className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white"
                      onClick={() => {
                        if (isImportAwaayClick || fileUploaded) {
                          handleOpen();
                        } else {
                          closeDialog();
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className={`flex px-8   py-1.5  border-grey-300 rounded-md text-white ${
                        fileUploaded && file != null
                          ? `bg-[#009CA7]`
                          : `bg-[#B3B3B3]`
                      }`}
                      disabled={!fileUploaded || file === null}
                      onClick={nextButtonClicked}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center items-center space-x-72">
                    <button
                      className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white"
                      onClick={() => {
                        if (isImportAwaayClick || fileUploaded) {
                          handleOpen();
                        } else {
                          closeDialog();
                        }
                      }}
                    >
                      Cancel
                    </button>
                    <div className="flex gap-3">
                      {nextButnClicked || isImportAwaayClick ? (
                        <button
                          className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white"
                          onClick={handlePrevClick}
                        >
                          Previous
                        </button>
                      ) : null}
                      {nextButnClicked ? (
                        <button
                          onClick={handleUpdateFarmerType}
                          disabled={
                            (filteredRows?.length === 0 &&
                              unsatisfiedRows?.length !== 0) ||
                            fileData?.length == 0 ||
                            dataLoading ||
                            dataImporting
                          }
                          className={`flex px-6 py-1.5 border-2 border-grey-300 rounded-md ${
                            (filteredRows?.length === 0 &&
                              unsatisfiedRows?.length !== 0) ||
                            fileData?.length == 0 ||
                            dataLoading ||
                            dataImporting
                              ? "bg-[#CED4DA]"
                              : "bg-[#009CA7]"
                          } text-white`}
                        >
                          Import
                        </button>
                      ) : null}
                    </div>
                  </div>
                )}
                </div>
              </footer>{" "}
              <AlertDialog
                closeDialog={closeDialog}
                handleClose={handleClose}
                open={openAlert}
              />
            </div>
          ) : (
            <div className="container min-w-full ">
              <div className="block sm:flex items-center min-w-full">
                <h1 className="text-[20px] font-bold px-3 py-3">
                  Bulk Data Update
                </h1>
              </div>
              <hr className="mt-0 border-gray-200" />

              <div
                onClick={() => handleClickOpenBulkUpload(true)}
                className="cursor-pointer flex items-center bg-white w-80 h-20 mt-3 ml-3 border border-white-400 rounded-md"
              >
                <img
                  src={basline_icon}
                  className="mt-1 ml-3 h-[52px] w-[45.5px]"
                  alt="Baseline Icon"
                />
                <h1 className="mt-1 ml-3 font-bold text-[16px]">
                  Baseline/Project Farmer
                </h1>
                <div className="ml-auto mr-3 mt-3">
                  <button onClick={() => handleClickOpenBulkUpload(true)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.70504 6L8.29504 7.41L12.875 12L8.29504 16.59L9.70504 18L15.705 12L9.70504 6Z"
                        fill="black"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <ReactLoadingOverlay
            classNamePrefix="growindigo_"
            active={true}
            spinner={<ClipLoader color="#27878E" />}
            text={""}
          >
            {/* Your content here */}
          </ReactLoadingOverlay>
        </>
      )}
    </>
  );
};

export default Bulk_Update;

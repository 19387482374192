import "./App.css";
import { Router, Switch, Route } from "react-router-dom";
import Login from "./Components/Login/Login";
//import { About} from './Components/About';
import { Layout } from "./Components/Layout";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./Utility/theme";
import { Provider } from "react-redux";
import { store } from "./Libraries/redux/store";
import LoadingOverlay from "./Components/Layout/LoadingOverlay";
import { APP_ROUTES } from "./Libraries/AppConstants";
import ProtectedRoute from "./Components/Layout/ProtectedRoute";
import history from "./history";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConfirmProvider } from "material-ui-confirm";
// import DropDown from "./Components/QC/DropDown";
import About from "./Components/About/About";

import Users from "./Components/Users";
import SampleFarmer from "./Components/SampleFarmer";
import Bulk_Update from "./Components/Bulk_Update";

function App() {
  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
          <LoadingOverlay>
            <ThemeProvider theme={theme}>
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: { autoFocus: true },
                }}
              >
                <Router history={history}>
                  <Switch>
                    <Route exact path={APP_ROUTES.LOGIN} component={Login} />
                    <Layout>
                      <ProtectedRoute exact path={APP_ROUTES.ABOUT} component={About}/>
                      <ProtectedRoute exact path={APP_ROUTES.Users} component={Users} />
                      <ProtectedRoute exact path={APP_ROUTES.SAMPLE_FARMER} component={SampleFarmer} />
                      <ProtectedRoute exact path={APP_ROUTES.Bulk_Update} component={Bulk_Update} />

                      {/* <ProtectedRoute exact path={APP_ROUTES.QCDASH} component={QCDASH}/> */}
                    </Layout>
                  </Switch>
                </Router>
              </ConfirmProvider>
            </ThemeProvider>
          </LoadingOverlay>
        </Provider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;

import { Component } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Card,
  CardContent,
  createStyles,
  Theme,
  withStyles,
  Button,
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import SnackBar from "../Alert";
import {
  sendOtpToMember,
  verifyGoogleLogin,
  verifyOtp,
} from "../../Libraries/redux/reducers/Auth/reducer";
import { connect } from "react-redux";
import history from "../../history";
import { APP_ROUTES } from "../../Libraries/AppConstants";
import StorageHelper from "../../Utility/StorageHelper";
import MicrosoftLogin from "./MicrosoftLogin";
import carbonlogo from "../../assets/images/carbon.png";

const useStyles = createStyles((theme: Theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  root: {
    minWidth: theme.spacing(46),
    width: "fit-content",
    margin: "auto",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image2: {
    maxWidth: "13%",
    maxHeight: "13%",
  },
  avatar: {
    maxWidth: "50%",
    maxHeight: "50%",
    margin: theme.spacing(1),
    paddingBottom: "15px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  non_gi_member_button: {
    marginTop: theme.spacing(2),
  },
  auth_title: {
    marginBottom: theme.spacing(2),
  },
  back: {
    display: "flex",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      opacity: 0.6,
    },
  },
}));

interface IProps {
  classes: any;
  sendOtpToMember: any;
  verifyGoogleLogin: any;
  verifyOtp: any;
  addSeller: any;
}

interface IState {
  mobile: any;
  errorMobile: any;
  otp: any;
  errorOtp: any;
  page: any;
  requesting: any;
  snackSuccessMessage: any;
  snackErrorMessage: any;
  loginWithOtp: any;
}

class Login extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    // alert(cookies.get("jwtToken"));
    this.state = {
      mobile: "",
      errorMobile: false,
      otp: "",
      errorOtp: false,
      page: "mobile",
      requesting: false,
      snackSuccessMessage: "",
      snackErrorMessage: "",
      loginWithOtp: false,
    };
  }
  componentDidMount() {
    let localStorageData =
      StorageHelper.getMultiple(["initiated-microsoft", "auth"]) || {};
    let clicked = localStorageData["initiated-microsoft"] || "";

    if (clicked === "1" && localStorageData["auth"]) {
      let auth = JSON.parse(localStorageData["auth"] || "");
      let user = {
        email: auth?.email,
        name: auth?.name,
      };

      if (user.email && user.name) {
        this.handleLogin({ google_login: user });
      } else {
        console.error("Missing required data for login");
      }
    }
  }

  render() {
    const classes = this.props.classes;

    return (
      <>
        <div className={classes.container}>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                  <img
                    className={classes.avatar}
                    src={carbonlogo}
                    alt="carbonlogo"
                  />
                  <MicrosoftLogin handleLogin={this.handleLogin} />
                </div>
                {this.state.snackSuccessMessage && (
                  <SnackBar
                    open={this.state.snackSuccessMessage ? true : false}
                    severity={"success"}
                    message={this.state.snackSuccessMessage}
                  ></SnackBar>
                )}
                {this.state.snackErrorMessage && (
                  <SnackBar
                    open={this.state.snackErrorMessage ? true : false}
                    severity={"error"}
                    message={this.state.snackErrorMessage}
                  ></SnackBar>
                )}
              </Container>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  alertMessage = (type: string, message: string) => {
    this.setState({ snackSuccessMessage: "", snackErrorMessage: "" });

    if (type === "success") {
      this.setState({ snackSuccessMessage: message });
    } else {
      this.setState({ snackErrorMessage: message });
    }
  };

  //On Success Login
  handleLogin = (status: any) => {
    let clicked = StorageHelper.get("initiated-google");
    let clickedMS = StorageHelper.get("initiated-microsoft");

    if (clicked === "1" || clickedMS === "1") {
      let ins_seller = {
        email: status?.google_login?.email,
        name: status?.google_login?.name,
        sellerTypeId: "3",
      };

      this.props
        .verifyGoogleLogin({
          emailId: ins_seller?.email,
          token: status.tokenId,
        })
        .then((response) => {
          if (response?.payload?.data?.status === "success") {
            if (response.payload?.data?.type === "exists") {
              return history?.replace(APP_ROUTES?.ABOUT);
            }
          }
        });
    }
  };
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  sendOtpToMember: (payload: any) => dispatch(sendOtpToMember(payload)),
  verifyOtp: (payload: any) =>
    dispatch(verifyOtp(payload)).then((response: any) => {
      if (response?.payload?.data?.status === "success") {
        return history?.replace(APP_ROUTES?.ABOUT);
      }
    }),
  verifyGoogleLogin: (payload: any) => dispatch(verifyGoogleLogin(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Login));

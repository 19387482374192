import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  CssBaseline,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationMenu from "./NavigationMenu";
import carbonlogo from "../../assets/images/carbon.png";
import { useHistory } from "react-router";
import StorageHelper from "../../Utility/StorageHelper";
import { Divider } from "@material-ui/core";
import { FnAuthenticatedRequest } from "../../Utility/Utils";

interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [partnerName, setPartnerName] = useState<string>("");

  const drawerWidth: number = 220;
  const miniDrawerWidth: number = 58;

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const history: any = useHistory();

  const handleClick = () => {
    history.push("/");
  };
  useEffect(() => {
    let authData = JSON.parse(StorageHelper.get("auth") || "{}");
    if (authData) {
      setUserName(authData.name);
    }
    fetchPartner();
  }, []);

  const fetchPartner = async () => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const response = await FnAuthenticatedRequest(
          "GET",
          "api/getPartnerKa",
          authHeader,
          ""
        );

        if (response?.success && response?.data?.length > 0) {
          const partnerId = authData?.partnerId;

          const matchingPartner = response?.data.find(
            (partner) => partner?.partnerId === partnerId
          );

          if (matchingPartner) {
            setPartnerName(matchingPartner?.name);
          } else {
            setPartnerName("No Partner");
          }
        }
      }
    } catch (error) {
      console.error("Error fetching partner:", error);
    }
  };

  return (
    <div>
      <CssBaseline />
      {/* App Bar */}
      <AppBar
        position="fixed"
        className={clsx("transition-all", {
          "ml-0": !isDrawerOpen,
          "ml-240": isDrawerOpen,
        })}
        color="inherit"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap>
            <img
              src={carbonlogo}
              alt="growinlogo Image"
              className="h-10 cursor-pointer ml-2.5"
              onClick={handleClick}
            />
          </Typography>
          <div className="lg:flex lg:items-center ml-auto">
            <p className="font-semibold lg:inline-block hidden">
              {partnerName}
            </p>
            <p className="lg:inline px-2 hidden">|</p>
            <p className="font-semibold lg:inline-block hidden">{userName}</p>
            <div className="lg:hidden">
              <p className="font-semibold block">{userName}</p>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        style={{ zIndex: 1 }}
      >
        <div
          style={{
            width: isDrawerOpen ? drawerWidth : miniDrawerWidth,
            overflow: "hidden",
            padding: "0px",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
            >
              <MenuIcon className="-ml-1.5" />
            </IconButton>
            <Typography variant="h6" noWrap>
              <img
                src={carbonlogo}
                alt="growinlogo Image"
                className="h-10 cursor-pointer ml-4"
                onClick={handleClick}
              />
            </Typography>
          </Toolbar>
          <Divider />
          <NavigationMenu
            isDrawerOpen={isDrawerOpen}
            handleDrawerOpen={() => setDrawerOpen(true)}
            handleDrawerClose={() => setDrawerOpen(false)}
          />
        </div>
      </Drawer>
      <div
        style={{
          marginLeft: isDrawerOpen ? drawerWidth : miniDrawerWidth,
          paddingTop: "0.6rem",
          transition: "margin 0.3s",
        }}
      >
        <main className="mt-14">{children}</main>
      </div>
    </div>
  );
};

export default Layout;

import { useContext } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { NavLink, useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../Libraries/AppConstants";
// import { ExitToApp } from "@material-ui/icons";
import StorageHelper from "../../Utility/StorageHelper";
import { signOutUser } from "../../Libraries/redux/reducers/Auth/reducer";
import { connect } from "react-redux";
import { MsalContext } from "@azure/msal-react";
import HomeIcon from "@material-ui/icons/Home";
import KaIcon from "../../assets/images/Ka_Icon.svg";
import farmer_list_icon from "../../assets/images/farmer_list_icon.svg";
import default_farmer_list_icon from "../../assets/images/active_farmer_list_icon.svg";
import default_KaIcon_url from "../../assets/images/active_KaIcon_url.svg";
import Logout_Icon from "../../assets/images/Logout_Icon.svg";
import default_dataUpdate_Icon from "../../assets/images/Bulk_Data_Update.svg";
import active_Data_Update_Icon from "../../assets/images/active_Data_Update_Icon.svg";

const useStyles = createStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  icon: {},
  navlink: {
    color: "#333333",
    textDecoration: "none",
  },
  current: {
    color: "#333333",
    textDecoration: "none",
    "& .MuiListItem-button, .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

const NavigationMenu = ({
  classes,
  signOutUser,
  handleDrawerOpen,
  handleDrawerClose,
  isDrawerOpen,
}) => {
  const history = useHistory();
  const msalContext = useContext(MsalContext);

  const signOut = () => {
    handleDrawerClose();

    signOutUser().then(() => {
      StorageHelper.removeAll();
      const msalInstance = msalContext.instance;
      msalInstance.logoutPopup();
      document.cookie.split(";").forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      history.replace(APP_ROUTES.LOGIN);
    });
  };

  return (
    <>
      <List>
        <div className="mt-3"></div>
        <NavLink
          to={APP_ROUTES.ABOUT}
          onClick={handleDrawerClose}
          activeClassName={classes.current}
          className={classes.navlink}
        >
          <ListItem button key={"Home"}>
            <ListItemIcon className={classes.listItem}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={isDrawerOpen ? "Home" : "Home"}
              className={classes.listItemText}
            />
          </ListItem>
        </NavLink>
        <NavLink
          to={APP_ROUTES.Users}
          onClick={handleDrawerClose}
          activeClassName={classes.current}
          className={classes.navlink}
        >
          <ListItem button key={"Users"}>
            <ListItemIcon className={classes.listItem}>
              <img
                src={
                  history.location.pathname === APP_ROUTES.Users
                    ? default_KaIcon_url
                    : KaIcon
                }
                alt="ka_icon"
              />
            </ListItemIcon>
            <ListItemText
              primary={isDrawerOpen ? "Kisan Advisor" : "KA"}
              className={classes.listItemText}
            />
          </ListItem>
        </NavLink>
        <NavLink
          to={APP_ROUTES.SAMPLE_FARMER}
          onClick={handleDrawerClose}
          activeClassName={classes.current}
          className={classes.navlink}
        >
          <ListItem button key={"sample_farmer"}>
            <ListItemIcon className={classes.listItem}>
              <img
                src={
                  history.location.pathname === APP_ROUTES.SAMPLE_FARMER
                    ? default_farmer_list_icon
                    : farmer_list_icon
                }
                alt="farmer_list_icon"
              />
            </ListItemIcon>
            <ListItemText
              primary={isDrawerOpen ? "Kisan Advisor" : "KA"}
              className={classes.listItemText}
            />
          </ListItem>
        </NavLink>

        <NavLink
          to={APP_ROUTES.Bulk_Update}
          onClick={handleDrawerClose}
          activeClassName={classes.current}
          className={classes.navlink}
        >
          <ListItem button key={"Users"}>
            <ListItemIcon className={classes.listItem}>
              <img
                src={
                  history.location.pathname === APP_ROUTES.Bulk_Update
                    ? active_Data_Update_Icon
                    : default_dataUpdate_Icon
                }
                alt="ka_icon"
              />
            </ListItemIcon>
            <ListItemText
              primary={isDrawerOpen ? "Bulk Data Update" : "Data"}
              className={classes.listItemText}
            />
          </ListItem>
        </NavLink>
        <ListItem
          button
          key={"Logout"}
          onClick={signOut}
          className={classes.navlink}
        >
          <ListItemIcon className={classes.listItem}>
            <img src={Logout_Icon} alt="farmer_list_icon" />
            {/* <ExitToApp /> */}
          </ListItemIcon>
          <ListItemText
            primary={isDrawerOpen ? "Logout" : "Logout"}
            className={classes.listItemText}
          />
        </ListItem>
      </List>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  signOutUser: (payload) => dispatch(signOutUser(payload)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(useStyles)(NavigationMenu));

import React, { useState, useEffect, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import guide from "../../assets/images/guidelines.svg";
import uploadIcon from "../../assets/images/upload.svg";
import tick from "../../assets/images/tick.svg";
import warning from "../../assets/images/warning.svg";
import checkStep from "../../assets/images/radio_button_checked.svg";
import * as XLSX from "xlsx";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import StorageHelper from "../../Utility/StorageHelper";
import { FnAuthenticatedRequest } from "../../Utility/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  tableContainer: {
    borderSpacing: "15px",
  },
  tableRow: {
    borderBottom: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  editIcon: {
    color: "gray",
  },
}));

export default function BulkUpload({ handleBack }) {
  const classes = useStyles();

  const [uploadedData, setUploadedData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isTableView, setIsTableView] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [sortByError, setSortByError] = useState(false);
  const [unSortedData, setUnSortedData] = useState([]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;
    if (file?.name !== fileName) {
      const reader = new FileReader();
      reader.onload = function (e) {
        try {
          const contents = e.target?.result as string;
          const workbook = XLSX.read(contents, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          const arrUploadedData: any = data.map((v: any) => {
            let soilSamplingObject = {
              farmerId: v["farmer_id"],
              fieldNumber: v["field_number"],
              isSampled: v["is_sampled"],
              year: v["year"],
              assignedUser: {
                soilSamplingUser: v["soil_sampling_user"],
                cropCuttingUser: v["crop_cutting_user"],
              },
            };
            return soilSamplingObject;
          });

          if (arrUploadedData?.length > 0) {
            setUploadedData(arrUploadedData);
            setFileName(file?.name);
          }
        } catch (error) {
          console.error("Error parsing file:", error);
        }
      };

      reader.onerror = function () {
        console.error("Failed to read the file.");
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleRemoveFile = () => {
    setFileName("");
    setUploadedData([]);
    // Reset file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const [erroedEntriesCount, setErroedEntriesCount] = useState(0);
  const [rightEntriesCount, setRightEntriesCount] = useState(
    uploadedData?.length
  );

  useEffect(() => {
    if (isValidated && uploadedData) {
      let trueCounter = 0;
      let falseCounter = 0;

      uploadedData.forEach((farmer: any) => {
        if (farmer && typeof farmer.isValidFarmer === "boolean") {
          if (farmer.isValidFarmer === true) {
            trueCounter++;
          } else {
            falseCounter++;
          }
        }
      });

      setRightEntriesCount(trueCounter);
      setErroedEntriesCount(falseCounter);
    }
  }, [uploadedData, isValidated]);

  const handleNext = () => {
    try {
      if (uploadedData?.length !== 0) {
        setIsTableView(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleValidate = async () => {
    try {
      if (uploadedData?.length !== 0) {
        let authData = JSON.parse(StorageHelper.get("auth") || "{}");
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = uploadedData;
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/validateFieldActivity",
          authHeader,
          payload
        );
        if (response?.success) {
          setUploadedData(response?.data);
          setUnSortedData(response?.data);
          setIsValidated(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePrev = () => {
    try {
      if (uploadedData?.length !== 0) {
        setIsTableView(false);
        setIsValidated(false);
        setSortByError(false);
        const b: any = unSortedData.map((farmer: any) => {
          let a = {
            farmerId: farmer?.farmerId,
            fieldNumber: farmer?.fieldNumber,
            isSampled: farmer?.isSampled,
            year: farmer?.year,
            assignedUser: {
              soilSamplingUser: farmer?.assignedUser?.soilSamplingUser,
              cropCuttingUser: farmer?.assignedUser?.cropCuttingUser,
            },
          };
          return a;
        });
        setUploadedData(b);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadExcel = (jsonObject, fileName) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(jsonObject);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      console.error(error);
    }
  };

  const generateErrorFilename = () => {
    try {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}${month}${day}`;
      const filename = `ErrorData_Soil_Sampling_${formattedDate}`;
      return filename;
    } catch (error) {
      console.error(error);
    }
  };

  const downloadErrorData = (data) => {
    try {
      const errorData = data
        .filter((farmer) => farmer && !farmer.isValidFarmer)
        .map((farmer) => {
          return {
            farmer_id: farmer?.farmerId,
            field_number: farmer?.fieldNumber,
            is_sampled: farmer?.isSampled,
            year: farmer?.year,
            soil_sampling_user: farmer?.assignedUser?.soilSamplingUser,
            crop_cutting_user: farmer?.assignedUser?.cropCuttingUser,
          };
        });
      const fileName = generateErrorFilename();
      downloadExcel(errorData, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (data) => {
    try {
      if (!Array.isArray(data)) {
        throw new Error("Data is not an array");
      }

      const inputData = data
      .filter((farmer) => farmer && farmer.isValidFarmer)
      .map((farmer) => {
        if (farmer && farmer.isSampled && farmer.isSampled.toUpperCase() === "Y") {
          return {
            //farmer_id: "123e4567-e89b-12d3-a456-426614174001",
             farmer_id: farmer.farmerId,
            field_id: farmer.fieldNumber,
            activity_year: parseInt(farmer.year),
            is_sampled: farmer.isSampled.toUpperCase(),
            soil_sampling: farmer.assignedUser ? farmer.assignedUser.soilSamplingUser : null,
            crop_cutting: farmer.assignedUser ? farmer.assignedUser.cropCuttingUser : null,
          };
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);
    
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const payload = inputData;
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/createFieldActivity",
        authHeader,
        payload
      );
      if (response?.success) {
        toast.success("New Entries Added Successfully.", {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#258750",
            color: "white",
          },
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSortByError = () => {
    try {
      if (isValidated) {
        let sortedData;
        if (sortByError) {
          setUploadedData(unSortedData);
        } else {
          sortedData = [...uploadedData].sort(
            (a: any, b: any) => a.isValidFarmer - b.isValidFarmer
          );
          setUploadedData(sortedData);
        }
        setSortByError(!sortByError);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="p-5">
      <div className="block sm:flex items-center">
        <ArrowBackIcon
          onClick={handleBack}
          className="cursor-pointer transition-all hover:text-[#009CA7] active:text-[#007a80]"
        />
        <h1 className="text-xl font-bold px-3 py-3">Bulk Upload</h1>
        <img src={guide} className="ml-auto mr-12 cursor-pointer" alt="guide" />
      </div>
      <hr className="mt-4 border border-gray-200" />
      <div className=" mt-5">
        <div className="flex justify-center items-center">
          <div className="flex flex-col items-center">
            <p
              className={`flex w-6 h-6 rounded-full items-center justify-center mr-0 bg-[#009CA7] text-white`}
            >
              {isTableView === false ? (
                <>1</>
              ) : (
                <img src={checkStep} alt="step1" />
              )}
            </p>
            <p>Upload</p>
          </div>
          <hr
            className={`w-56 border-2 border-t ${
              !isTableView ? `border-gray-300` : `border-[#009CA7]`
            }  -mt-6 -mx-3.5`}
          />
          <div className="flex flex-col items-center">
            <p
              className={`flex w-6 h-6 rounded-full items-center justify-center mr-0 ${
                isTableView
                  ? `bg-[#009CA7] text-white`
                  : `bg-gray-300 text-gray-400`
              } `}
            >
              2
            </p>
            <p>View</p>
          </div>
        </div>
      </div>
      {isTableView === true ? (
        <div>
          <div className="flex pb-2">
            <p className="text-sm py-2">
              Showing {uploadedData?.length} entries
            </p>
            <div className="ml-auto">
              <FormControlLabel
                style={{
                  color: "#666666",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                }}
                control={
                  <Switch
                    color="primary"
                    checked={sortByError}
                    onChange={handleSortByError}
                  />
                }
                label="Sort by errors at top"
              />
            </div>
          </div>
          <div className="bg-white p-3">
            <div className="flex gap-2">
              {uploadedData?.length !== erroedEntriesCount && (
                <div className="font-bold py-3">
                  <span className="flex flex-row">
                    <img src={tick} alt="tick" />{" "}
                    <p className="text-[#009CA7] px-1">
                      {isValidated === true
                        ? rightEntriesCount
                        : uploadedData?.length}{" "}
                      Entries
                    </p>
                    are ready to be imported
                  </span>
                </div>
              )}

              {isValidated && (
                <>
                  {uploadedData?.length !== erroedEntriesCount && (
                    <span className="h-7 border-l border-2 border-gray-300 mx-1 mt-2"></span>
                  )}

                  <div className="font-bold py-3">
                    <span className="flex flex-row">
                      <img src={warning} alt="tick" />{" "}
                      <p className="text-[#FF0000] px-1">
                        {erroedEntriesCount} Entries
                      </p>
                      {uploadedData?.length === erroedEntriesCount
                        ? `contains error, fix error before you import.`
                        : `will be skipped if you import.`}
                      {/* will be skipped if you import.{" "} */}
                      {uploadedData?.length !== erroedEntriesCount && (
                        <span
                          className="text-[#009CA7] px-1 cursor-pointer underline"
                          onClick={() => {
                            downloadErrorData(uploadedData);
                          }}
                        >
                          Download
                        </span>
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>
            <hr className="border border-gray-300" />
            <TableContainer component={Paper} className="mt-3 h-64">
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow className="bg-[#E5F5F6] text-nowrap">
                    <TableCell className={classes.bold}>Farmer Id</TableCell>
                    <TableCell className={classes.bold}>Field Number</TableCell>
                    <TableCell className={classes.bold}>Is Sampled</TableCell>
                    <TableCell className={classes.bold}>Year</TableCell>
                    <TableCell className={classes.bold}>
                      Soil sampling user
                    </TableCell>
                    <TableCell className={classes.bold}>
                      Crop cutting user
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedData?.map((farmer: any, index) => {
                    return (
                      <TableRow key={index} className="border-0 text-nowrap">
                        <TableCell
                          className={`${
                            farmer?.isValid?.isFarmerIdValid === false &&
                            `bg-red-400`
                          }`}
                        >
                          {farmer?.farmerId}
                        </TableCell>
                        <TableCell
                          className={`${
                            farmer?.isValid?.isFieldNumberValid === false &&
                            `bg-red-400`
                          }`}
                        >
                          {farmer?.fieldNumber}
                        </TableCell>
                        <TableCell
                          className={`${
                            farmer?.isValid?.isSampledValid === false &&
                            `bg-red-400`
                          }`}
                        >
                          {farmer?.isSampled}
                        </TableCell>
                        <TableCell
                          className={`${
                            farmer?.isValid?.isYearValid === false &&
                            `bg-red-400`
                          }`}
                        >
                          {farmer?.year}
                        </TableCell>
                        <TableCell
                          className={`${
                            farmer?.isValid?.assignedUser?.soilSamplingUser ===
                              false && `bg-red-400`
                          }`}
                        >
                          {farmer?.assignedUser?.soilSamplingUser}
                        </TableCell>
                        <TableCell
                          className={`${
                            farmer?.isValid?.assignedUser?.cropCuttingUser ===
                              false && `bg-red-400`
                          }`}
                        >
                          {farmer?.assignedUser?.cropCuttingUser}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-10">
            <div className="flex justify-center mt-0">
              <div
                className={`border-2 border-dashed border-opacity-50 border-gray-400 ${
                  uploadedData?.length === 0 ? `bg-white` : `bg-green-300`
                } flex flex-col justify-center items-center px-28 py-12`}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  id="fileInput"
                  accept=".csv, .xlsx"
                  className="hidden"
                  onChange={handleFileUpload}
                />
                <img
                  src={uploadIcon}
                  alt="Bulk Upload Img"
                  className="cursor-pointer"
                  onClick={() => document.getElementById("fileInput")?.click()}
                />
                {fileName !== "" && (
                  <div>
                    <span className="text-wrap w-2">{fileName}</span>
                    <span
                      className="cursor-pointer text-2xl text-red-500 ml-2"
                      onClick={handleRemoveFile}
                    >
                      x
                    </span>
                  </div>
                )}

                <p
                  className="text-[#009CA7] cursor-pointer font-semibold mt-4 text-base"
                  onClick={() => document.getElementById("fileInput")?.click()}
                >
                  {uploadedData?.length === 0 ? "Upload File" : "Replace File"}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center mt-7">
            <h1 className="text-base">
              Use csv format, or{" "}
              <a
                href="/soil_sampling_template.xlsx"
                className="text-[#009CA7] font-bold"
                download
              >
                Download Template
              </a>
            </h1>
          </div>
        </>
      )}
      <div className="flex fixed bottom-6 left-16 right-0 justify-center items-center gap-10 sm:gap-96">
        <button
          className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white mr-4"
          onClick={handleBack}
        >
          Cancel
        </button>
        {isTableView ? (
          <div className="flex gap-3">
            <button
              className={`flex px-8 py-1.5 border-2 border-grey-300 rounded-md text-dark bg-white`}
              onClick={handlePrev}
            >
              Prev
            </button>
            <button
              className={`flex px-8 py-1.5 border-2 border-grey-300 rounded-md text-white ${
                (uploadedData?.length === 0 ||
                  uploadedData?.length === erroedEntriesCount) &&
                isValidated
                  ? `bg-gray-400`
                  : `bg-[#009CA7]`
              } `}
              disabled={
                uploadedData?.length === erroedEntriesCount && isValidated
              }
              onClick={() => {
                if (isValidated) {
                  handleSubmit(uploadedData);
                } else {
                  handleValidate();
                }
              }}
            >
              {isValidated === true ? `Import` : `Validate`}
            </button>
          </div>
        ) : (
          <button
            className={`flex px-8 py-1.5 border-2 border-grey-300 rounded-md text-white ${
              uploadedData?.length === 0 ? `bg-gray-400` : `bg-[#009CA7]`
            } `}
            onClick={handleNext}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
}

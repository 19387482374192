import React, { Component } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Layout } from "../Layout";
import growindigologo from "../../assets/images/go.png";
import carbonlogo from "../../assets/images/carbon.png";

const useStyles = (theme: Theme) =>
  createStyles({
    // Add or modify styles as needed
    imageContainer: {
      textAlign: "center",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    image2: {
      maxWidth: "13%",
      maxHeight: "13%",
    },
    centeredText: {
      textAlign: "center",
      fontFamily: "Open Sans, sans-serif",
    },
    root: {
      backgroundColor: "#F4F4F7",
    },
  });

interface IProps extends WithStyles<typeof useStyles> {}

interface IState {}

class About extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    return (
        <div className="container mt-40 px-5">
          <div className="">
            <img
              src={growindigologo}
              alt="growinlogo Image"
              className="mx-auto my-5"
            />
            <p className={classes.centeredText}>
              Grow Indigo is a joint initiative of Mahyco Grow and Indigo Ag
              established in 2017. Combining the technological innovation of the
              two market leaders we will help maximize farmers' benefits. Our
              primary objective is to tackle the challenges farmers face every
              day and assist them to improve their yields, generate more revenue
              and implement sustainable farming practices.
            </p>
          </div>

          <div className="mt-20">
            <img
              src={carbonlogo}
              alt="growinlogo Image"
              className="mx-auto h-10"
            />
            <p className={classes.centeredText}>
              Empowering Farmers, Nurturing The Earth
            </p>
          </div>
        </div>
    );
  }
}

export default withStyles(useStyles)(About);

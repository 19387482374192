import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Tooltip,
  Box,
} from "@material-ui/core";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";
import LocationDetails from "./LocationDetails";
import AddUsers from "./Addusers";
import Search_Icon from "../../assets/images/Search_Icon.svg";
import Bulk_Upload from "../../assets/images/Bulk_Upload.svg";
import handleDateFormatWithTime, {
  FnAuthenticatedRequest,
  handleDateFormat,
  handleSearchValidation,
} from "../../Utility/Utils";
import edit from "../../assets/editIcon.svg";
import { Pagination } from "@material-ui/lab";
import Menu from "./Menu";
import {
  userTablePageNumber,
  userTablePageSize,
} from "../../Utility/constants";
import StorageHelper from "../../Utility/StorageHelper";
import BulkUpload from "./BulkUpload";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../Libraries/AppConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  tableContainer: {
    borderSpacing: "15px",
  },
  tableRow: {
    borderBottom: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  editIcon: {
    color: "gray",
  },
}));

const Users: React.FC = () => {
  const classes = useStyles();
  const [users, setUsers] = useState<any>([]);
  const [partnersList, setPartnersList] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(userTablePageSize);
  const [userCount, setUserCount] = useState(0);
  const [districtParams, setDistrictParams] = useState(null);
  const [statesParams, setStatesParams] = useState(null);
  const [territoryParams, setTerritoryParams] = useState(null);
  const [isTestUserParam, setIsTestUserParam] = useState(false);
  const [statusParam, setStatusParam] = useState("");
  const [roleParams, setRoleParams] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  const [searchInput, setSearchInput] = useState("");
  const [prevInput, setPrevInput] = useState("");
  const [fromEditUser, setFromEditUser] = useState<boolean>(false);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [bulkUpload, setBulkUpload] = useState<boolean>(false);
  const [editUserData, setEditUserData] = useState<any>(null);
  // const [usersData, setUserData] = useState<any>(null);
  const [arrRoles, setArrRoles] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState(false);

  const handleClickOpen = (editUser, userData) => {
    setOpen(true);
    setFromEditUser(editUser);
    setEditUserData(userData);
    setSearchInput("");
    setPageNumber(0);
  };

  const handleClickOpenBulkUpload = (bulkUpload) => {
    setOpen(true);
    setBulkUpload(bulkUpload);
    // setUserData(users);
    setPartnersList(partnersList);
    setSearchInput("");
    setPageNumber(0);
  };
  const handleClose = () => {
    setOpen(false);
    setFromEditUser(false);
    setBulkUpload(false);
    setEditUserData(null);
    handleSetMetaParams(null, null, null, null, false, "", setSearchInput);
  };
  const history = useHistory();

  const handleGetAllKA: any = useCallback(
    async (params) => {
      try {
        setDataLoading(true);
        const authData = JSON.parse(localStorage.getItem("auth") || "{}");
        if (authData) {
          const authHeader = {
            JWTToken: authData.jwtToken,
            userId: authData.userId,
          };
          const payload = params;
          const response: any = await FnAuthenticatedRequest(
            "POST",
            "api/getAllKa",
            authHeader,
            payload
          );

          if (response?.success) {
            // setTimeout(() => {
            //   setDataLoading(false);
            // }, 200);
            setUsers(response?.data);
            setFilteredUsers(response?.data);
            setUserCount(response?.data[0]?.count || 0);
            return response?.data;
          }
        }
      } catch (error: any) {
        // setDataLoading(false);

        if (error.response && error.response.status) {
          const statusCode = error.response.status;
          if (statusCode === 401) {
            StorageHelper.removeAll();
            document.cookie.split(";").forEach(function (c) {
              document.cookie = c
                .replace(/^ +/, "")
                .replace(
                  /=.*/,
                  "=;expires=" + new Date().toUTCString() + ";path=/"
                );
            });

            history.replace(APP_ROUTES.LOGIN);
          } else {
            console.error(error);
          }
        } else {
          console.error(error);
        }
      }
      // eslint-disable-next-line
    },
    [history]
  );

  const handleSearchInput = async (users, input) => {
    try {
      setPrevInput(input);
      setSearchInput(input);

      if (input?.length > 3 && input !== prevInput) {
        const params = {
          pageNo: 0,
          pageSize: userTablePageSize,
          states: statesParams,
          territories: territoryParams,
          districts: districtParams,
          roles: roleParams,
          isTestUser: isTestUserParam,
          status: statusParam,
          searchInput: input,
        };

        setApiCalled(true);
        await handleGetAllKA(params);
      } else if (input?.length <= 3 && prevInput !== null && apiCalled) {
        const params = {
          pageNo: 0,
          pageSize: userTablePageSize,
          states: statesParams,
          territories: territoryParams,
          districts: districtParams,
          roles: roleParams,
          isTestUser: isTestUserParam,
          status: statusParam,
          searchInput: null,
        };
        setPageNumber(0);

        setApiCalled(false);
        await handleGetAllKA(params);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!open) {
      const fetchData = async () => {
        try {
          const params = {
            pageNo: pageNumber,
            pageSize: pageSize,
            status: "",
            states: null,
            territories: null,
            districts: null,
            isTestUser: false,
            roles: null,
            searchInput: null,
          };
          const kaUsers = await handleGetAllKA(params);
          if (kaUsers) {
            setUsers(kaUsers);
            if (searchInput) {
              handleSearchInput(kaUsers, searchInput);
            }
          }
        } catch (error) {
          console.error("Error in getAllKa:", error);
        }
      };
      fetchData();
      fnGetPartnerList();
    }
  }, [open]);

  useEffect(() => {
    if (searchInput) {
      handleSearchInput(users, searchInput);
    }
  }, [searchInput, users]);
  useEffect(() => {
    if (
      (partnersList?.length > 0 && users?.length > 0 && arrRoles?.length > 0) ||
      users?.length === 0
    ) {
      setDataLoading(false);
    }
  }, [partnersList, users, arrRoles]);

  const fnGetPartnerList = async () => {
    try {
      setDataLoading(true);

      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };

        const response: any = await FnAuthenticatedRequest(
          "GET",
          "api/getPartnerKa",
          authHeader,
          ""
        );
        if (response?.success) {
          // setDataLoading(false);
          setPartnersList(response?.data);
        }
      }
    } catch (error: any) {
      // setDataLoading(false);
      if (error?.response && error?.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  partners data:", error);
        }
      } else {
        console.error("Error fetching  partnes data:", error);
      }
    }
  };

  // const filterUsers = (users, searchInput) => {
  //   const search = searchInput.toLowerCase();
  //   return users.filter(
  //     (user) =>
  //       (user?.name && user?.name.toLowerCase().includes(search)) ||
  //       (user?.mobile && user?.mobile.toString().includes(search))
  //   );
  // };

  const handleResetPageNumber = () => {
    try {
      setPageNumber(userTablePageNumber);
      setPageSize(userTablePageSize);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetMetaParams: any = (
    stateParams,
    territoryParams,
    disctrictParams,
    roleParams,
    isTestUserParam,
    statusParam,
    searchInput
  ) => {
    try {
      setStatesParams(stateParams);
      setTerritoryParams(territoryParams);
      setDistrictParams(disctrictParams);
      setIsTestUserParam(isTestUserParam);
      setStatusParam(statusParam);
      setRoleParams(roleParams);
      setSearchInput(searchInput);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value - 1);
    const input = searchInput?.length > 3 ? searchInput : "";
    const params = {
      pageNo: value - 1,
      pageSize: pageSize,
      states: statesParams,
      territories: territoryParams,
      districts: districtParams,
      roles: roleParams,
      isTestUser: isTestUserParam,
      status: statusParam,
      searchInput: input,
    };
    handleGetAllKA(params);
  };

  const maskMobileNumber = (mobile: string): string => {
    const visiblePart: string = mobile?.substring(0, 1);
    const maskedPart: string = "XXXXXX";
    const lastThreeDigits: string = mobile.substring(7);
    const maskedNumber: string = visiblePart + maskedPart + lastThreeDigits;
    return maskedNumber;
  };

  return (
    <div className="container min-w-full">
      {!open ? (
        <>
          <div className="flex px-5 py-3">
            <h1 className="text-xl md:text-3xl font-bold">Kisan Advisor</h1>
            <div className="ml-auto flex gap-3">
              <button
                className="bg-[#009CA7] text-sm p-2 px-3 rounded text-white transition-all hover:bg-[#007a80] active:bg-[#00666c]"
                onClick={() => {
                  handleClickOpen(false, null);
                }}
              >
                <span className="text-sm">+</span> Add New
              </button>
              {/* <button className="bg-white md:p-2 md:px-3 rounded text-[#009CA7] border-2 border-[#009CA7] transition-all hover:bg-[#e0f2f1] hover:text-[#007a80] active:bg-[#b2dfdb] active:border-[#b2dfdb]"> */}

              <button onClick={() => handleClickOpenBulkUpload(true)}>
                {/* <FileDownloadOutlinedIcon /> */}
                <img src={Bulk_Upload} alt="bulk_upload" />
              </button>
            </div>
          </div>
          <hr className="my-1 border-[1.5px]" />
          <div className="min-w-full">
            <div className="container px-5 py-2 min-w-full">
              <LocationDetails
                fnGetAllKa={handleGetAllKA}
                handleResetPageNumber={handleResetPageNumber}
                handleSetMetaParams={handleSetMetaParams}
                setSearchInput={searchInput}
                setArrRoles={setArrRoles}
              />
            </div>

            <div className="m-3 mt-6 border-2 border-gray-300 bg-[#E5F5F6]">
              <div className="block sm:flex bg-[#E5F5F6]">
                <div className="search-container my-3 mx-2">
                  <input
                    type="text"
                    placeholder="Search by Name, Mobile number"
                    style={{ fontSize: "12px" }}
                    className="search-input min-w-[100%] sm:min-w-[125%]"
                    onKeyPress={handleSearchValidation}
                    value={searchInput}
                    onChange={(e: any) => {
                      handleSearchInput(users, e.target.value);
                    }}
                  />
                  <div className="search-icon">
                    <img src={Search_Icon} alt="Search_Icon" />
                  </div>
                </div>
                <div className="flex ml-auto gap-3 items-center px-3">
                  <p className="text-[#009CA7] text-sm pb-2 ml-auto font-bold">
                    Count - {userCount}
                  </p>
                </div>
              </div>

              <hr className="border border-gray-200" />

              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow className="bg-[#E5F5F6] text-nowrap">
                      <TableCell className={classes.bold}>Sr.no</TableCell>
                      <TableCell className={classes.bold}>Action</TableCell>
                      <TableCell className={classes.bold}>Status</TableCell>
                      <TableCell className={classes.bold}>ID</TableCell>
                      <TableCell className={classes.bold}>Name</TableCell>
                      <TableCell className={classes.bold}>Mobile No</TableCell>
                      <TableCell className={classes.bold}>Role</TableCell>
                      {/* <TableCell className={classes.bold}>
                        Reporting Manager ID
                      </TableCell> */}
                      <TableCell className={classes.bold}>
                        Partner Name
                      </TableCell>
                      <TableCell className={classes.bold}>Zone</TableCell>
                      <TableCell className={classes.bold}>State</TableCell>
                      <TableCell className={classes.bold}>Territory</TableCell>
                      <TableCell className={classes.bold}>District</TableCell>
                      <TableCell className={classes.bold}>
                        Joining Date
                      </TableCell>
                      <TableCell className={classes.bold}>Exit Date</TableCell>
                      <TableCell className={classes.bold}>Added On</TableCell>
                      <TableCell className={classes.bold}>Added By</TableCell>
                      <TableCell className={classes.bold}>Test User</TableCell>
                    </TableRow>
                  </TableHead>
                  <>
                    {!dataLoading ? (
                      <>
                        <TableBody>
                          {filteredUsers?.length > 0 ? (
                            filteredUsers?.map((user: any, index) => (
                              <TableRow
                                key={index}
                                className="border-0 text-nowrap"
                              >
                                <TableCell className="border-0">
                                  {user?.serialNo}
                                </TableCell>
                                <TableCell>
                                  <Tooltip
                                    title="Edit"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      handleClickOpen(true, user);
                                    }}
                                  >
                                    <img src={edit} alt="Pencil" />
                                  </Tooltip>
                                </TableCell>
                                <TableCell>
                                  <span
                                    style={{
                                      color:
                                        user?.rolesForKa[0]?.status === true
                                          ? "#2C8B5E"
                                          : "red",
                                    }}
                                  >
                                    {user?.rolesForKa[0]?.status === true
                                      ? "Active"
                                      : "InActive"}
                                  </span>
                                </TableCell>
                                <TableCell>{user?.userId}</TableCell>
                                <TableCell>{user?.name}</TableCell>
                                <TableCell>
                                  {maskMobileNumber(user?.mobile)}
                                </TableCell>{" "}
                                {/* <TableCell>KA</TableCell> */}
                                <TableCell>
                                  {" "}
                                  {arrRoles?.find(
                                    (role) =>
                                      role?.value ===
                                      user?.rolesForKa[0]?.roleId
                                  )?.label
                                    ? arrRoles?.find(
                                        (role) =>
                                          role?.value ===
                                          user?.rolesForKa[0]?.roleId
                                      )?.label
                                    : "n/a"}
                                </TableCell>
                                {/* <TableCell>
                            {user?.rolesForKa?.length > 0 &&
                            (user?.rolesForKa[0]?.reportingManager === null ||
                              user?.rolesForKa[0]?.reportingManager === "")
                              ? "n/a"
                              : user?.rolesForKa[0]?.reportingManager}
                          </TableCell> */}
                                <TableCell>
                                  {partnersList?.find(
                                    (partner) =>
                                      partner?.partnerId === user?.partnerId
                                  )
                                    ? partnersList?.find(
                                        (partner) =>
                                          partner?.partnerId === user?.partnerId
                                      ).name
                                    : "n/a"}
                                </TableCell>
                                <TableCell>
                                  {user?.territoryMap?.zone ? (
                                    <>
                                      {user?.territoryMap?.zone?.length === 1 &&
                                        user?.territoryMap?.zone.map(
                                          (zone, index) => {
                                            return (
                                              <Fragment key={index}>
                                                {zone?.zoneName?.toUpperCase()}
                                              </Fragment>
                                            );
                                          }
                                        )}
                                      {user?.territoryMap?.zone &&
                                      user?.territoryMap?.zone?.length > 1 ? (
                                        <span>
                                          {arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "NM"
                                            ? "All"
                                            : user?.territoryMap?.zone?.length +
                                              " SELECTED"}
                                        </span>
                                      ) : (
                                        user?.territoryMap?.zone?.length ===
                                          0 &&
                                        (arrRoles
                                          ?.find(
                                            (role) =>
                                              role?.value ===
                                              user?.rolesForKa[0]?.roleId
                                          )
                                          ?.label.toUpperCase() === "NM"
                                          ? "All"
                                          : "n/a")
                                      )}
                                    </>
                                  ) : (
                                    "n/a"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {/* {user?.territoryMap?.state !== undefined && */}
                                  {user?.territoryMap?.state ? (
                                    <>
                                      {user?.territoryMap?.state?.length ===
                                        1 &&
                                        user?.territoryMap?.state.map(
                                          (state, index) => {
                                            return (
                                              <Fragment key={index}>
                                                {arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() ===
                                                  "ZBM" ||
                                                arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() === "NM"
                                                  ? "All"
                                                  : state.stateName?.toUpperCase()}
                                              </Fragment>
                                            );
                                          }
                                        )}

                                      {user?.territoryMap?.state &&
                                      user?.territoryMap?.state?.length > 1 ? (
                                        <span>
                                          {arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "NM" ||
                                          arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "ZBM"
                                            ? "All"
                                            : user?.territoryMap?.state
                                                ?.length + " SELECTED"}
                                        </span>
                                      ) : (
                                        user?.territoryMap?.state?.length ===
                                          0 &&
                                        (arrRoles
                                          ?.find(
                                            (role) =>
                                              role?.value ===
                                              user?.rolesForKa[0]?.roleId
                                          )
                                          ?.label.toUpperCase() === "NM" ||
                                        "ZBM"
                                          ? "All"
                                          : "n/a")
                                      )}
                                    </>
                                  ) : (
                                    "n/a"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {user?.territoryMap?.territory !==
                                    undefined &&
                                  user?.territoryMap?.territory ? (
                                    <>
                                      {user?.territoryMap?.territory?.length ===
                                        1 &&
                                        user?.territoryMap?.territory.map(
                                          (territory, index) => {
                                            return (
                                              <Fragment key={index}>
                                                {arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() ===
                                                  "RBM" ||
                                                arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() ===
                                                  "ZBM" ||
                                                arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() === "NM"
                                                  ? "All"
                                                  : territory.territoryName?.toUpperCase()}
                                              </Fragment>
                                            );
                                          }
                                        )}
                                      {user?.territoryMap?.territory &&
                                      user?.territoryMap?.territory?.length >
                                        1 ? (
                                        <span>
                                          {arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "NM" ||
                                          arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "ZBM" ||
                                          arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "RBM"
                                            ? "All"
                                            : user?.territoryMap?.territory
                                                ?.length + " SELECTED"}
                                        </span>
                                      ) : (
                                        user?.territoryMap?.territory
                                          ?.length === 0 &&
                                        (arrRoles
                                          ?.find(
                                            (role) =>
                                              role?.value ===
                                              user?.rolesForKa[0]?.roleId
                                          )
                                          ?.label.toUpperCase() === "NM" ||
                                        "RBM" ||
                                        "ZBM"
                                          ? "All"
                                          : "n/a")
                                      )}
                                    </>
                                  ) : (
                                    "n/a"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {/* user?.territoryMap?.district !== undefined && */}
                                  {user?.territoryMap?.district ? (
                                    <>
                                      {user?.territoryMap?.district?.length ===
                                        1 &&
                                        user?.territoryMap?.district.map(
                                          (district, index) => {
                                            return (
                                              <Fragment key={index}>
                                                {arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() ===
                                                  "RBM" ||
                                                arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() ===
                                                  "ZBM" ||
                                                arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() ===
                                                  "TBM" ||
                                                arrRoles
                                                  ?.find(
                                                    (role) =>
                                                      role?.value ===
                                                      user?.rolesForKa[0]
                                                        ?.roleId
                                                  )
                                                  ?.label.toUpperCase() === "NM"
                                                  ? "All"
                                                  : district.districtName?.toUpperCase()}
                                              </Fragment>
                                            );
                                          }
                                        )}
                                      {user?.territoryMap?.district &&
                                      user?.territoryMap?.district?.length >
                                        1 ? (
                                        <span>
                                          {arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "NM" ||
                                          arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "ZBM" ||
                                          arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "RBM" ||
                                          arrRoles
                                            ?.find(
                                              (role) =>
                                                role?.value ===
                                                user?.rolesForKa[0]?.roleId
                                            )
                                            ?.label.toUpperCase() === "TBM"
                                            ? "All"
                                            : user?.territoryMap?.district
                                                ?.length + " SELECTED"}
                                        </span>
                                      ) : (
                                        user?.territoryMap?.district?.length ===
                                          0 &&
                                        (arrRoles
                                          ?.find(
                                            (role) =>
                                              role?.value ===
                                              user?.rolesForKa[0]?.roleId
                                          )
                                          ?.label.toUpperCase() === "NM" ||
                                        "RBM" ||
                                        "ZBM" ||
                                        "TBM"
                                          ? "All"
                                          : "n/a")
                                      )}
                                    </>
                                  ) : (
                                    "n/a"
                                  )}
                                </TableCell>
                                <TableCell>
                                  {user?.rolesForKa?.length > 0 &&
                                    handleDateFormat(
                                      user?.rolesForKa[0]?.joinedDate
                                    )}
                                </TableCell>
                                <TableCell>
                                  {user?.rolesForKa?.length > 0 &&
                                  user?.rolesForKa[0]?.exitDate === null
                                    ? "n/a"
                                    : handleDateFormat(
                                        user?.rolesForKa[0]?.exitDate
                                      )}
                                </TableCell>
                                <TableCell>
                                  {user?.createdAt &&
                                    handleDateFormatWithTime(user?.createdAt)}
                                </TableCell>
                                <TableCell>
                                  {user?.createdBy ? user?.createdBy : ""}
                                </TableCell>
                                {/* <TableCell>{user?.createdBy}</TableCell> */}
                                <TableCell>
                                  {user?.isTestUser === true ? (
                                    <p className="text-center">Y</p>
                                  ) : (
                                    <p className="text-center">N</p>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={17} className="text-center">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "300px",
                                  }}
                                >
                                  <p>No Records Found</p>
                                </Box>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </>
                    ) : (
                      <>
                        <ReactLoadingOverlay
                          classNamePrefix="growindigo_"
                          active={true}
                          spinner={<ClipLoader color="#27878E" />}
                          text={""}
                        >
                          {/* Your content here */}
                        </ReactLoadingOverlay>
                      </>
                    )}
                  </>
                </Table>
              </TableContainer>
              <div className="flex flex-col bg-[#F4F4F7] md:flex md:flex-row items-center justify-center py-5">
                <Pagination
                  count={
                    users?.length > 0
                      ? filteredUsers &&
                        Math.ceil(filteredUsers[0]?.count / pageSize)
                      : 2
                  }
                  disabled={filteredUsers?.length === 0 ? true : false}
                  page={pageNumber + 1}
                  shape="rounded"
                  onChange={handlePageChange}
                />
                <Menu
                  pageSize={pageSize}
                  changeDefaultTotal={(number) => {
                    const params = {
                      pageNo: 0,
                      pageSize: number,
                      states: statesParams,
                      territories: territoryParams,
                      districts: districtParams,
                      roles: roleParams,
                      isTestUser: isTestUserParam,
                      status: statusParam,
                      searchInput: searchInput,
                    };
                    handleGetAllKA(params);
                    setPageSize(number);
                    setPageNumber(0);
                  }}
                  perPage={[10, 15, 25]}
                />
              </div>
            </div>
          </div>
        </>
      ) : open === true && bulkUpload === true ? (
        <BulkUpload
          closeDialog={handleClose}
          bulkUpload={bulkUpload}
          users={users}
          // partnesList={partnersList}
        />
      ) : (
        <AddUsers
          closeDialog={handleClose}
          fromEditUser={fromEditUser}
          editUserData={editUserData}
        />
      )}
    </div>
  );
};

export default Users;

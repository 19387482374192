const {
    REACT_APP_BASE_URL,
    REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
    REACT_APP_ENABLE_WALLET,
    REACT_APP_MICROSOFT_LOGIN_CLIENT_ID,
    REACT_APP_GI_TENANT_URL,
} = process.env;

let config = {
    REACT_APP_BASE_URL: REACT_APP_BASE_URL || "",
    GOOGLE_LOGIN_CLIENT_ID: REACT_APP_GOOGLE_LOGIN_CLIENT_ID || "",
    REACT_APP_ENABLE_WALLET: REACT_APP_ENABLE_WALLET === 'true',
    REACT_APP_ENABLE_DATADUMPSQL:true,
    // IMAGES_DIRECTORY: "https://products.growindigo.co.in/otherImages/",
    // PRODUCT_IMAGES_DIRECTORY: "https://products.growindigo.co.in/shop_image/",
    MICROSOFT_LOGIN_CLIENT_ID: REACT_APP_MICROSOFT_LOGIN_CLIENT_ID || "",
    MICROSOFT_LOGIN_TENANT_ID: REACT_APP_GI_TENANT_URL || "",
}

export default config;
import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Search_Icon from "../../assets/images/Search_Icon.svg";
import assign_to_icon from "../../assets/images/assign_to.svg";
import Excel_Icon from "../../assets/images/Excel_Icon.svg";
import handleDateFormatWithTime from "../../Utility/Utils";
import { fieldActivityPageSize } from "../../Utility/constants";
import { Pagination } from "@material-ui/lab";
import DefaultMenu from "../Users/Menu";

const SampleFarmerList = ({
  handleOpenAssignModal,
  setSelectedSampledFarmers,
  arrActivities,
  handleFilter,
  filterParams,
  totalCount,
}) => {
  const [activities, setActivities] = useState(arrActivities);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(fieldActivityPageSize);
  const [searchText, setSearchText] = useState("");

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll(event.target.checked);
    const updatedRows: any = activities.map((row: any) => ({
      ...row,
      selected: event.target.checked,
    }));
    setActivities(updatedRows);
    if (event.target.checked) {
      setSelectedActivities(updatedRows);
      setSelectedSampledFarmers(updatedRows);
    } else {
      setSelectedSampledFarmers([]);
      setSelectedActivities([]);
    }
  };

  const handleFarmerSelect = (value, index: number) => {
    const updatedRows: any = [...activities];
    updatedRows[index].selected = value;
    setActivities(updatedRows);
  };

  const handleAddFarmer = (farmer) => {
    try {
      const index = selectedActivities.findIndex(
        (f: any) => f.activityId === farmer.activityId
      );
      const updatedFarmers: any =
        index !== -1
          ? selectedActivities.filter(
              (f: any) => f.activityId !== farmer.activityId
            )
          : [...selectedActivities, farmer];
      setSelectedActivities(updatedFarmers);
      setSelectedSampledFarmers(updatedFarmers);
      if (updatedFarmers?.length === activities?.length) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (arrActivities?.length > 0) {
      setActivities(arrActivities);
    } else {
      setActivities([]);
    }
  }, [arrActivities]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value - 1);
    const input = searchText?.length > 3 ? searchText : "";
    const params = {
      page_no: value === 1 ? 1 : value,
      page_size: pageSize,
      year: 2024,
      status: "Y",
      states: [],
      districts: [],
      search_text: input,
    };
    handleFilter(params);
  };
  const [selectedDistrict, setSelectedDistrict] = useState("");

  useEffect(() => {
    if (selectedActivities?.length === 0) {
      setSelectedDistrict("");
    }
  }, [selectedActivities]);

  function notAllSameDistrictId(arr) {
    if (arr.length === 0) return false; // Assuming empty array means false
    const firstDistrictId = arr[0].districtId;
    return !arr.every((item) => item.districtId === firstDistrictId);
  }

  return (
    <div className="container px-5">
      <div className="block border border-gray-400 sm:flex bg-[#E5F5F6] p-3">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by Name, Mobile number"
            className="search-input-farmer w-full"
            onChange={(e: any) => {
              if (e.target.value.length > 3 || e.target.value.length === 0) {
                handleFilter({ ...filterParams, search_text: e.target.value });
                setSearchText(e.target.value);
              }
            }}
          />
          <div className="search-icon">
            <img src={Search_Icon} alt="Search_Icon" />
          </div>
        </div>
        <div className="my-0 mx-3 ml-auto">
          <div className="flex justify-center items-center gap-3">
            <button
              type="button"
              className={`flex gap-2 p-1.5 px-2 border-white justify-center items-center border-2 ${
                selectedActivities?.length > 0 ? `bg-[#009CA7]` : `bg-[#CCCCCC]`
              }`}
              onClick={
                selectedActivities?.length > 0 ? handleOpenAssignModal : null
              }
            >
              <img src={assign_to_icon} alt="Search_Icon" />
              <span className="text-white">Assign to</span>
            </button>

            <span className="text-2xl text-gray-300">|</span>
            <p className="font-semibold text-gray-700">Count - 102</p>
            <button
              type="button"
              className="flex gap-2 p-1.5 border-2 border-[#009CA7] justify-center items-center bg-white"
            >
              <img src={Excel_Icon} alt="Search_Icon" />
              <span className="text-[#009CA7] font-semibold px-1">Export</span>
            </button>
          </div>
        </div>
      </div>
      <div className="border border-gray-400">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className="bg-[#E5F5F6] text-nowrap">
                <TableCell>
                  <Checkbox
                    size="small"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    disabled={notAllSameDistrictId(activities)}
                  />
                </TableCell>
                <TableCell>Sr.no</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Farmer name</TableCell>
                <TableCell>State</TableCell>
                <TableCell>District</TableCell>
                <TableCell>Soil sampling</TableCell>
                <TableCell>Assigned to S.S</TableCell>
                <TableCell>Crop cutting</TableCell>
                <TableCell>Assigned to C.C</TableCell>
                <TableCell>Added on</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activities?.length > 0 ? (
                activities.map((row: any, index) => (
                  <TableRow
                    key={index}
                    className="border-0 text-nowrap hover:bg-gray-100 cursor-pointer select-none"
                    onClick={() => {
                      if (
                        selectedDistrict === "" ||
                        selectedDistrict === row.districtId
                      ) {
                        handleFarmerSelect(!row.selected, index);
                        handleAddFarmer(row);
                        setSelectedDistrict(row.districtId);
                      }
                    }}
                  >
                    <TableCell className="border-0"
                    onClick={(event)=>{
                      event.stopPropagation();
                    }}>
                      <Checkbox
                        size="small"
                        checked={row?.selected || false}
                        onChange={(event: any) => {
                          event.stopPropagation();
                          if (
                            selectedDistrict === "" ||
                            selectedDistrict === row.districtId
                          ) {
                            handleFarmerSelect(!row.selected, index);
                            handleAddFarmer(row);
                            setSelectedDistrict(row.districtId);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell className="border-0">
                      {row?.activityId}
                    </TableCell>
                    <TableCell>{row?.activityYear}</TableCell>
                    <TableCell>{row?.farmerName}</TableCell>
                    <TableCell>{row?.stateName}</TableCell>
                    <TableCell>{row?.districtName}</TableCell>
                    <TableCell>
                      {row?.soilSampling !== "" && row?.soilSampling !== null
                        ? "Y"
                        : "N"}
                    </TableCell>
                    <TableCell>
                      {row?.soilSampling !== "" && row?.soilSampling !== null
                        ? row?.soilSampling
                        : "n/a"}
                    </TableCell>
                    <TableCell>
                      {row?.cropCutting !== "" && row?.cropCutting !== null
                        ? "Y"
                        : "N"}
                    </TableCell>
                    <TableCell>
                      {row?.cropCutting !== "" && row?.cropCutting !== null
                        ? row?.cropCutting
                        : "n/a"}
                    </TableCell>
                    <TableCell>
                      {row?.createdAt &&
                        handleDateFormatWithTime(row?.createdAt)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={17} className="text-center">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "300px",
                      }}
                    >
                      <p>No Records Found</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex flex-col bg-[#F4F4F7] md:flex md:flex-row items-center justify-center py-5">
          <Pagination
            count={
              activities?.length > 0 ? Math.ceil(totalCount / pageSize) : 0
            }
            disabled={activities?.length === 0 ? true : false}
            page={pageNumber + 1}
            shape="rounded"
            onChange={handlePageChange}
          />
          <DefaultMenu
            pageSize={fieldActivityPageSize}
            changeDefaultTotal={(number) => {
              const params = {
                page_no: 0,
                page_size: number,
                year: 2024,
                status: "Y",
                states: [],
                districts: [],
                search_text: "",
              };
              setPageSize(number);
              setPageNumber(0);
              handleFilter(params);
            }}
            perPage={[10, 15, 25]}
          />
        </div>
      </div>
    </div>
  );
};
export default SampleFarmerList;

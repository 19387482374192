
const APP_ROUTES = {
    LOGIN: "/",
    ABOUT: "/about",
    QCDASH:"/QCDASH",
    DropDown:"/Dropdown",
    Users:"/Users",
    SAMPLE_FARMER:"/sample_farmer",
    Bulk_Update:"/Bulk_Update"
};

const API_CONSTANTS = {
    APPLICATION_CODE: 'GO',
    APP_CODE: 'IAMDASHBRD',
    APP_VERSION: 1
}

export { APP_ROUTES, API_CONSTANTS };

import { Button, Menu, MenuItem } from "@material-ui/core";
import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const perPage: any = [10, 15, 20, 25, 50];

interface IProps {
  changeDefaultTotal: any;
  pageSize: any;
  perPage?: any;
}

export default function DefaultMenu(props: IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [value, setValue] = React.useState<null | HTMLElement>(props.pageSize);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeValue = (e_total) => {
    // setValue(e_total);
    handleClose();
    props?.changeDefaultTotal(e_total);
  };

  let _perPage: any = props.perPage || perPage;

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {props.pageSize}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {_perPage.map((e_total,index) => {
          return (
            <MenuItem key={index} onClick={() => changeValue(e_total)}>{e_total}</MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

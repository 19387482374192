import React, { useState, useEffect } from "react";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import { MultiSelect } from "react-multi-select-component";
import refresh from "../../assets/images/refresh.svg";
import { FormControlLabel, Switch } from "@material-ui/core";
import {
  userTablePageNumber,
  userTablePageSize,
} from "../../Utility/constants";
import Select from "react-select";
import StorageHelper from "../../Utility/StorageHelper";
import "react-toastify/dist/ReactToastify.css";
import { APP_ROUTES } from "../../Libraries/AppConstants";
import { useHistory } from "react-router-dom";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";

interface Option {
  label: string;
  value: string;
}

interface GetAllKa {
  fnGetAllKa: (params) => void;
  handleResetPageNumber: () => void;
  handleSetMetaParams: (
    stateParams,
    territoryParams,
    districtParams,
    roleParams,
    isTestUser,
    statusParam,
    setSearchInput
    // searchInput,
  ) => void;
  setSearchInput;
  setArrRoles: (params) => void;
}

const LocationDetails: React.FC<GetAllKa> = ({
  fnGetAllKa,
  handleResetPageNumber,
  handleSetMetaParams,
  setSearchInput,
  setArrRoles,
}) => {
  const [stateOptions, setStateOptions] = useState<Option[]>([]);
  const [selectedStates, setSelectedStates] = useState<Option[]>([]);

  const [districtOptions, setDistrictOptions] = useState<Option[]>([]);
  const [selectedDistricts, setSelectedDistricts] = useState<Option[]>([]);

  const [territoryOptions, setTerritoryOptions] = useState<Option[]>([]);
  const [selectedTerritories, setSelectedTerritories] = useState<Option[]>([]);

  const [rolesOptions, setRoleOptions] = useState<Option[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>([]);

  const [userStatus, setUserStatus] = useState<Option | null>(null);

  const [isTestUser, setIsTestUser] = useState(false);
  const [stateDisabled, setStateDisabled] = useState(false);
  const [territoryDisabled, setTrritoryDisabled] = useState(false);
  const [districtDisabled, setDistrictDisabled] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [datatrloading, setTrDataLoading] = useState(false);
  const [datadiloading, setDiDataLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    fetchDataFromApi();
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const fetchDataFromApi = async () => {
    try {
      setDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const response: any = await FnAuthenticatedRequest(
          "GET",
          "api/GetStateDetails",
          authHeader,
          ""
        );
        if (response?.success) {
          const formattedData = response?.data?.state_master?.map((state) => ({
            label: state?.state_name?.toUpperCase(),
            value: state?.state_id,
            state_name: state?.state_name?.toUpperCase(),
          }));
          setStateOptions(formattedData);
          setDataLoading(false);
        }
      }
    } catch (error: any) {
      setDataLoading(false);
      console.error("Error fetching  data:", error);
      if (error?.response && error?.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  data:", error);
        }
      } else {
        console.error("Error fetching  data:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedStates?.length > 0 && selectedRole !== "ZBM") {
      fetchStateWiseTeritaory(selectedStates.map((state) => state.value));
    }
    // eslint-disable-next-line
  }, [selectedStates]);

  useEffect(() => {
    if (selectedTerritories?.length > 0) {
      fetchDistricts(selectedTerritories.map((state) => state.value));
    }
    // eslint-disable-next-line
  }, [selectedTerritories]);

  useEffect(() => {
    const stateIds = selectedStates.map((state: any) => state.value);
    const filteredTerritories = selectedTerritories.filter((territory: any) =>
      stateIds.includes(territory.stateId)
    );
    if (selectedStates?.length === 0) {
      setTerritoryOptions([]);
    }
    setSelectedTerritories(filteredTerritories);
    // eslint-disable-next-line
  }, [selectedStates]);

  useEffect(() => {
    const territoryIds = selectedTerritories.map(
      (territory: any) => territory.value
    );
    const filteredDistricts = selectedDistricts.filter((district: any) =>
      territoryIds.includes(district.territoryId)
    );
    if (selectedTerritories?.length === 0) {
      setDistrictOptions([]);
    }
    setSelectedDistricts(filteredDistricts);
    // eslint-disable-next-line
  }, [selectedTerritories]);

  const fetchStateWiseTeritaory = async (arrStateIds: string[]) => {
    try {
      setTrDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = {
          state_id: arrStateIds,
        };
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/GetTeritatoryDetails",
          authHeader,
          payload
        );

        if (response?.success) {
          setTrDataLoading(false);
          const data: any = response?.data?.teritatory_master;

          const filteredData = Object.entries(data).map(
            ([key, value]: [any, any]) => {
              return { key, value };
            }
          );
          const restructuredObject: any = filteredData?.map((i) => {
            const trObject = {
              stateId: i.key,
              tr: i.value,
            };
            return trObject;
          });

          let territoryOptions: any = [];
          restructuredObject?.forEach((state) => {
            state.tr?.forEach((tr) => {
              let trObject = {
                label: tr.territory_name?.toUpperCase(),
                value: tr.territory_id,
                trId: tr.territory_id,
                territoryName: tr.territory_name?.toUpperCase(),
                stateId: tr.state_id,
              };
              territoryOptions.push(trObject);
            });
            if (arrStateIds?.length > 0) {
              setTerritoryOptions(territoryOptions);
            }
          });
        } else {
          console.error("Error fetching data :", response.data);
        }
      }
    } catch (error: any) {
      setTrDataLoading(false);
      console.error("Error fetching  states:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  states:", error);
        }
      } else {
        console.error("Error fetching  states:", error);
      }
    }
  };
  const fetchDistricts = async (arrTrIds: string[]) => {
    try {
      setDiDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const payload = {
        territory_id: arrTrIds,
      };
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/TeritatoryWiseDistrict",
        authHeader,
        payload
      );

      if (response?.success) {
        setDiDataLoading(false);
        const data: any = response?.data;
        let districtOptions = data?.map((district) => {
          let districtObject = {
            label: district.district_name?.toUpperCase(),
            value: district.district_id,
            districtId: district.district_id,
            districtName: district.district_name?.toUpperCase(),
            stateId: district.state_id,
            territoryId: district.territory_id,
          };
          return districtObject;
        });
        if (arrTrIds?.length > 0) {
          setDistrictOptions(districtOptions);
        }
      } else {
        console.error("Error fetching districts:", response.data);
      }
    } catch (error: any) {
      setDiDataLoading(false);
      console.error("Error fetching  districts:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  districts:", error);
        }
      } else {
        console.error("Error fetching  districts:", error);
      }
    }
  };

  const fetchRoles = async () => {
    try {
      setDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const response: any = await FnAuthenticatedRequest(
          "GET",
          "api/getRoles",
          authHeader,
          ""
        );
        if (response?.success) {
          setDataLoading(false);
        }
        const formattedData = response?.data
          ?.filter(
            (role) =>
              role.name &&
              ["ZBM", "RBM", "KA", "TBM", "NM"].some((keyword) =>
                role.name.includes(keyword)
              )
          )
          ?.map((role) => ({
            label: role.name?.toUpperCase(),
            value: role.roleId,
            role_name: role.name?.toUpperCase(),
          }));

        setRoleOptions(formattedData);
        setArrRoles(formattedData);
      }
    } catch (error: any) {
      setDataLoading(false);
      console.error("Error fetching  roles:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  roles:", error);
        }
      } else {
        console.error("Error fetching  roles:", error);
      }
    }
  };
  const clearSelections = () => {
    const input = setSearchInput?.length > 3 ? setSearchInput : null;
    setSelectedStates([]);
    setSelectedRole([]);
    setSelectedDistricts([]);
    setSelectedTerritories([]);
    setTerritoryOptions([]);
    setDistrictOptions([]);
    setStateDisabled(false);
    setDistrictDisabled(false);
    setIsTestUser(isTestUser);
    setUserStatus(null);
    handleResetPageNumber();
    handleSetMetaParams(null, null, null, null, isTestUser, "", input);

    const params = {
      pageNo: userTablePageNumber,
      pageSize: userTablePageSize,
      status: "",
      states: null,
      territories: null,
      districts: null,
      isTestUser: isTestUser,
      roles: null,
      searchInput: input,
    };
    fnGetAllKa(params);
  };

  const statusOptions: Option[] = [
    { label: "Active", value: "Y" },
    { label: "In-Active", value: "N" },
  ];

  const customMessages = {
    allItemsAreSelected: "All",
    selectSomeItems: "All",
    selectAllFiltered: "All",
  };

  useEffect(() => {
    if (selectedDistricts?.length && districtOptions?.length) {
      const filteredSelectedDistricts = selectedDistricts.filter((district) =>
        districtOptions.some((option) => option.value === district.value)
      );
      setSelectedDistricts(filteredSelectedDistricts);
    }
    // eslint-disable-next-line
  }, [districtOptions]);

  const customDisplayValueStates = () => {
    if (
      selectedStates?.length === 0 ||
      selectedStates?.length === stateOptions?.length
    ) {
      return "All";
    } else {
      return `${selectedStates?.length} selected`;
    }
  };
  const customDisplayValueDistrict = () => {
    if (
      selectedDistricts?.length === 0 ||
      selectedDistricts?.length === districtOptions?.length
    ) {
      return "All";
    } else {
      return `${selectedDistricts?.length} selected`;
    }
  };

  const handleSwitchTestUser = (event) => {
    setIsTestUser(event.target.checked);
    handleApply(event.target.checked);
  };

  useEffect(() => {
    if (selectedRole?.label === "NM" || selectedRole?.label === "ZBM") {
      setStateDisabled(true);
      setTrritoryDisabled(true);
      setDistrictDisabled(true);
      setSelectedStates([]);
      setSelectedTerritories([]);
      setSelectedDistricts([]);
    } else if (selectedRole?.label === "RBM") {
      setStateDisabled(false);
      setTrritoryDisabled(true);
      setDistrictDisabled(true);
      setSelectedTerritories([]);
      setSelectedDistricts([]);
    } else if (selectedRole?.label === "TBM") {
      setStateDisabled(false);
      setTrritoryDisabled(false);
      setDistrictDisabled(true);
      setSelectedDistricts([]);
    } else {
      setStateDisabled(false);
      setTrritoryDisabled(false);
      setDistrictDisabled(false);
    }
  }, [selectedRole]);

  const handleApply = (isTestUser) => {
    try {
      handleResetPageNumber();
      const arrStates = selectedStates.map((state: any) => state?.value);
      const arrTerritories = selectedTerritories.map(
        (territory: any) => territory?.value
      );
      const arrDisctricts = selectedDistricts.map(
        (district: any) => district?.value
      );

      const arrRoles = selectedRole?.value;

      const roleParams = arrRoles?.length > 0 ? [arrRoles] : null;
      const stateParams = arrStates?.length > 0 ? arrStates : null;
      const territoryParams =
        arrTerritories?.length > 0 ? arrTerritories : null;
      const districtParams = arrDisctricts?.length > 0 ? arrDisctricts : null;

      const statusParam =
        userStatus?.value === "Y" ? "Y" : userStatus?.value === "N" ? "N" : "";
      const isTestUserParam = isTestUser;
      const input = setSearchInput?.length >= 4 ? setSearchInput : "";
      const params = {
        pageNo: 0,
        pageSize: userTablePageSize,
        status: statusParam,
        states: stateParams,
        territories: territoryParams,
        districts: districtParams,
        isTestUser: isTestUserParam,
        roles: roleParams,
        searchInput: input,
      };
      handleSetMetaParams(
        stateParams,
        territoryParams,
        districtParams,
        roleParams,
        isTestUserParam,
        statusParam,
        input
      );
      fnGetAllKa(params);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="card min-w-full">
      <div className="card-body min-w-full">
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-3 md:grid-cols-7 md:gap-5 min-w-full">
          {/* Status */}
          <div>
            <label>Status</label>
            <div className="relative block">
              <Select
                onChange={(option: any) => {
                  setUserStatus(option);
                }}
                isSearchable={false}
                options={statusOptions}
                isClearable={true}
                placeholder="All"
                value={userStatus}
              />
            </div>
          </div>

          {/* State */}
          <div>
            <label id="state">State</label>
            <MultiSelect
              options={stateOptions}
              value={selectedStates}
              disabled={stateDisabled}
              onChange={setSelectedStates}
              hasSelectAll={false}
              labelledBy="state"
              valueRenderer={customDisplayValueStates}
              overrideStrings={customMessages}
              shouldToggleOnHover={false}
            />
          </div>

          {/* Territory */}
          <div>
            <label id="territory-add-user">Territory</label>
            {!datatrloading ? (
              <MultiSelect
                options={territoryOptions}
                value={selectedTerritories}
                onChange={setSelectedTerritories}
                labelledBy="territory-add-user"
                hasSelectAll={false}
                disabled={territoryDisabled}
                overrideStrings={customMessages}
                shouldToggleOnHover={false}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <MultiSelect
                  options={territoryOptions}
                  value={selectedTerritories}
                  onChange={setSelectedTerritories}
                  labelledBy="territory-add-user"
                  hasSelectAll={false}
                  overrideStrings={customMessages}
                  shouldToggleOnHover={false}
                  disabled={true}
                  disableSearch={true}
                />
                {/* Loading spinner */}
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                >
                  {/* Your content here */}
                </ReactLoadingOverlay>
              </div>
            )}
          </div>

          {/* District */}
          <div>
            <label id="disctrict">District</label>
            {!datadiloading ? (
              <MultiSelect
                options={districtOptions}
                value={selectedDistricts}
                onChange={setSelectedDistricts}
                disabled={districtDisabled}
                labelledBy="disctrict"
                hasSelectAll={false}
                valueRenderer={customDisplayValueDistrict}
                overrideStrings={customMessages}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <MultiSelect
                  options={districtOptions}
                  value={selectedDistricts}
                  onChange={setSelectedDistricts}
                  labelledBy="disctrict"
                  hasSelectAll={false}
                  valueRenderer={customDisplayValueDistrict}
                  overrideStrings={customMessages}
                  disabled={true}
                  disableSearch={true}
                />
                {/* Loading spinner */}
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                >
                  {/* Your content here */}
                </ReactLoadingOverlay>
              </div>
            )}
          </div>
          <div>
            <label id="role">Role</label>
            {!dataLoading ? (
              <Select
                placeholder="All"
                options={rolesOptions}
                value={selectedRole}
                onChange={setSelectedRole}
                isClearable={true}
              />
            ) : (
              <div>
                <Select
                  placeholder="All"
                  options={rolesOptions}
                  value={selectedRole}
                  onChange={setSelectedRole}
                  isClearable={true}
                  isDisabled={true}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      backgroundColor: state.isDisabled
                        ? "white"
                        : base.backgroundColor,
                      borderColor: "#ccc",
                      "&:hover": {
                        borderColor: "#27878E",
                      },
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: "white",
                    }),
                  }}
                />
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                ></ReactLoadingOverlay>
              </div>
            )}
          </div>

          {/* Buttons */}
          <div className="flex gap-4 items-center mt-4 min-w-full">
            <button
              className="bg-white text-[#406BBE] font-semibold border border-[#406BBE] px-5 py-1.5 rounded transition-all hover:bg-[#406BBE] hover:text-white active:bg-[#34508B] active:border-[#34508B]"
              onClick={() => {
                handleApply(isTestUser);
              }}
            >
              Apply
            </button>
            <button onClick={clearSelections}>
              <img src={refresh} alt="home_icon" />
            </button>
          </div>
          <div className="mt-5 test-user min-w-full">
            <div className="text-gray-500 text-center border border-gray-300 rounded-xl bg-white select-none">
              <FormControlLabel
                style={{
                  color: "#666666",
                  fontFamily: "Open Sans",
                  fontSize: "12px",
                }}
                control={
                  <Switch
                    color="primary"
                    checked={isTestUser}
                    onChange={handleSwitchTestUser}
                  />
                }
                label="Test User"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationDetails;

// import { Button } from '@material-ui/core';
// import React from 'react';
// import closeButton from "../../assets/images/closeButton.svg"
// import { LoadingOverlay } from '../Layout';


// const GuideLines = ({ isOpen, onClose }) => {
//   if (!isOpen) return null;

//   const handleClose = () => {
//     onClose(); 
//   };

//   return (
// <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
//       <div className="bg-white p-8 rounded-md max-w-lg w-full relative">
//         <div className="flex justify-between items-center mb-4" style={{ color: '#333333' }}>
//   <h2 className="text-sm font-bold">Guidelines</h2> 
//   <button onClick={handleClose}>
//     <svg className="w-4 h-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//     </svg>
//   </button>
// </div>

// <LoadingOverlay isOpen={isOpen} children={undefined} />

//  <div className="mb-4 text-xs"> {/* Apply text size 14px */}
//   <p className="mb-2">1. Enter the same values (Match the space and letters) as mentioned for already added KA or shown during the manual entry of the KA.</p> {/* Apply vertical spacing */}
//   <p className="mb-2">2. If the respective row has multiple values, please use a comma separator without space to add multiple values in one row.</p> {/* Apply vertical spacing */}
//   <p className="mb-2">3. Do not add any new district, state, territory, zone, partner name, or reporting manager's mobile number in the respective rows. The new value will get rejected and shown as an error while uploading. Please contact the tech team for any new entry.</p> {/* Apply vertical spacing */}
//   <p className="mb-2">4. Refer to the mandatory location values required for respective roles mentioned in the heading of the template file.</p> {/* Apply vertical spacing */}
//   <p className="mb-2">5. For the test user, mention the answer as Yes or No according to the need.</p> {/* Apply vertical spacing */}
// </div>


//         <div className="flex justify-end">

         
//         <Button variant="contained"  onClick={handleClose} style={{ color: '#FFFFFF', backgroundColor: '#009CA7',fontSize: '14px' }}>Close</Button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GuideLines;
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";


export default function GuideLines({ isOpen, onClose }) {

  const handleClose = () => {
        onClose(); 
      };
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-md max-w-lg w-full relative">
        <div className="flex justify-between items-center mb-4" style={{ color: '#333333' }}>
  <h2 className="text-sm font-bold">Guidelines</h2> 
  <button onClick={handleClose}>
    <svg className="w-4 h-4 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
</div>


 <div className="mb-4 text-xs"> {/* Apply text size 14px */}
  <p className="mb-2">1. Enter the same values (Match the space and letters) as mentioned for already added KA or shown during the manual entry of the KA.</p> {/* Apply vertical spacing */}
  <p className="mb-2">2. If the respective row has multiple values, please use a comma separator without space to add multiple values in one row.</p> {/* Apply vertical spacing */}
  <p className="mb-2">3. Do not add any new district, state, territory, zone, partner name, or reporting manager's mobile number in the respective rows. The new value will get rejected and shown as an error while uploading. Please contact the tech team for any new entry.</p> {/* Apply vertical spacing */}
  <p className="mb-2">4. Refer to the mandatory location values required for respective roles mentioned in the heading of the template file.</p> {/* Apply vertical spacing */}
  <p className="mb-2">5. For the test user, mention the answer as Yes or No according to the need.</p> {/* Apply vertical spacing */}
</div>


        <div className="flex justify-end">

         
        <Button variant="contained"  onClick={handleClose} style={{ color: '#FFFFFF', backgroundColor: '#009CA7',fontSize: '14px' }}>Close</Button>
        </div>
      </div>
    </div>
      </Dialog>
    </React.Fragment>
  );
}

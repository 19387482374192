import { graphConfig } from "../../authConfig";

const {
    REACT_APP_API_IAM_SYSTEM,
    REACT_APP_API_INVENTORY_SYSTEM,
} = process.env;

export default class Routes {
    static AUTH = class {
        static SEND_OTP = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Login/send-otp",
            method: "POST",
            requireAuth: false,
        };

        static VERIFY_OTP = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Login/verify-otp",
            method: "POST",
            requireAuth: false,
        };

        static GOOGLE_SIGNIN = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Login/google",
            method: "POST",
            requireAuth: false,
        };

        static SIGNOUT = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/signout",
            method: "POST",
            requireAuth: false,
        };

        static GRAPHME = {
            baseUrl: graphConfig.graphMeEndpoint,
            endPoint: '',
            method: 'GET',
            requireAuth: true,
            customHeaders: {
              Authorization: '',
            },
        };
    };

    static ROLES = class {

        static FETCH_ROLES = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Roles/affected-users",
            method: "GET",
            requireAuth: true,
        };

        static CREATE_ROLE = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Roles",
            method: "POST",
            requireAuth: true,
        };

        static UPDATE_ROLE = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Roles",
            method: "PUT",
            requireAuth: true,
        };

        static DELETE_ROLE = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Roles",
            method: "DELETE",
            requireAuth: true,
        };

    }

    static PRIVILEGES = class {

        static FETCH_ALL_MODULES = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/ModulesMaster",
            method: "GET",
            requireAuth: true,
        };

        static FETCH_ALL_MODULES_ROLES = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/ModulesRolesMapping",
            method: "GET",
            requireAuth: true,
        };

        static DELETE_MODULE_ROLE = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/ModulesRolesMapping",
            method: "DELETE",
            requireAuth: true,
        };

        static CREATE_MODULE_ROLE = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/ModulesRolesMapping",
            method: "POST",
            requireAuth: true,
        };

    }

    static USERS = class {

        static ALL_USERS_LIST = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Users",
            method: "GET",
            requireAuth: true,
        };

        static CHANGE_USERS_STATUS = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Users",
            method: "DELETE",
            requireAuth: true,
        };

        static ALL_STATES_LIST = {
            baseUrl: REACT_APP_API_INVENTORY_SYSTEM,
            endPoint: "/api/state",
            method: "GET",
            requireAuth: true,
        };

        static DISTRICTS_LIST = {
            baseUrl: REACT_APP_API_INVENTORY_SYSTEM,
            endPoint: "/api/districtdata",
            method: "POST",
            requireAuth: true,
        };

        static TALUKAS_LIST = {
            baseUrl: REACT_APP_API_INVENTORY_SYSTEM,
            endPoint: "/api/taluka",
            method: "GET",
            requireAuth: true,
        };

        static VILLAGES_LIST = {
            baseUrl: REACT_APP_API_INVENTORY_SYSTEM,
            endPoint: "/api/village",
            method: "GET",
            requireAuth: true,
        };

        static ADD_USER = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Users",
            method: "POST",
            requireAuth: true,
        };

        static UPDATE_USER = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/Users",
            method: "PUT",
            requireAuth: true,
        };

        static SAVE_META_INFO = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/meta",
            method: "POST",
            requireAuth: true,
        }

        static FETCH_META_INFO = {
            baseUrl: REACT_APP_API_IAM_SYSTEM,
            endPoint: "/api/meta",
            method: "GET",
            requireAuth: true,
        }
    }

}

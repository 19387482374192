class AuthHelper {
    authenticated = false;

    isAuthenticated() {
        try {
            const { jwtToken } = this.getSessionData();
            this.authenticated = jwtToken;
        } catch (err) {
            console.log("Auth error auth helper", err);
        }
        return this.authenticated;
    }

    getSessionData() {
        let authData = localStorage.getItem("auth") || "{}";
        const { jwtToken = null } = JSON.parse(authData) || null;
        let userData = localStorage.getItem("auth") || "{}";
        const userDetail = JSON.parse(userData) || null;
        return { userDetail, jwtToken };
    }

    getMemberData(){
        let authData = localStorage.getItem("auth") || "{}";
        const { jwtToken = null, userId = null, email = null } = JSON.parse(authData) || null;

        return { userId, jwtToken, email };
    }

    
    getSingleProp(key = null) {
        const sessionData = this.getSessionData();
        const user = sessionData || null;
        // console.log("getUser() Called", user);
        return key ? user?.[key] : user;
    }

    getAuthHeaders() {
        const {userId,jwtToken}=this.getMemberData();
        return {
            Authorization: `Bearer ${jwtToken}`,
            JWTToken: jwtToken,
            userId:userId,
        };
    }

    signout(callback) {
        localStorage.clear();
        callback();
        // Toast.success("Logged Out Successfully!");
    }
}

export default new AuthHelper();

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RESTClient from "../../../networking/RESTClient";
import Routes from "../../../networking/routes";
import { handleResponse } from "../../../../Utility/APIHandler";
import StorageHelper from "../../../../Utility/StorageHelper";

const initialState = {
  pending: false,
  login: false,
  error: "Yes",
};

export const sendOtpToMember = createAsyncThunk(
  "sendOtpToMember",
  async (payload: any, thunkAPI: any) => {
    try {
      const client = new RESTClient(Routes.AUTH.SEND_OTP);

      const response = await client.call(payload);

      console.log(
        "Send OTP To Member Reducer (API Call) - Response ",
        response.data
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "verifyOtp",
  async (payload: any, thunkAPI: any) => {
    try {
      const client = new RESTClient(Routes.AUTH.VERIFY_OTP);

      payload.appCode = "GO";

      const response = await client.call(payload);

      console.log("Verify Otp (API Call) - Response ", response.data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyGoogleLogin = createAsyncThunk(
  "verifyGoogleLogin",
  async (payload: any, thunkAPI: any) => {
    try {
      let customHeaders = { gtoken: payload.token };

      const client = new RESTClient({
        ...Routes.AUTH.GOOGLE_SIGNIN,
        customHeaders,
      });

      delete payload.token;

      payload.appCode = "GO";

      const response = await client.call(payload);

      console.log("Verify Google Login (API Call) - Response ", response.data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signOutUser = createAsyncThunk(
  "signOutUser",
  async (payload: any, thunkAPI: any) => {
    try {
      const client = new RESTClient(Routes.AUTH.SIGNOUT);

      const response = await client.call(payload);

      console.log("Signout (API Call) - Response ", response.data);

      return response.data;
    } catch (error) {
      debugger;
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const handleHotSecretData = (payload: any) => {
  StorageHelper.set("auth", payload?.data);

  let access: any = payload?.data?.access;
  let mapping: any = access?.mapping;
  let menu: any = {};

  for (var key in access?.master) {
    let value = access?.master.hasOwnProperty(key) ? access?.master[key] : {};
    if (value?.type === "menu" && mapping?.indexOf(key) !== -1) {
      menu[value?.name] = value;
    }
  }

  StorageHelper.set("menu", JSON.stringify(menu));
};

export const getGraphProfile = createAsyncThunk(
  "getGraphProfile",
  async (payload: any, thunkAPI: any) => {
    try {
      let config: any = { ...Routes.AUTH.GRAPHME };

      config.customHeaders.Authorization = "Bearer " + payload?.token;

      const client = new RESTClient(config);

      const response = await client.call({});

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const authSlice: any = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    // Initiate Phone Verification status
    [`${sendOtpToMember.pending}`]: (state, action) => {
      console.log("Send Otp To Member - Pending", state, action);

      state.pending = true;

      return state;
    },
    [`${sendOtpToMember.rejected}`]: (state, action) => {
      console.log("Send Otp To Member - Rejected", state, action);

      state.pending = false;

      state.error = action.error.message;

      handleResponse(action);

      return state;
    },
    [`${sendOtpToMember.fulfilled}`]: (state, action) => {
      console.log("Send Otp To Member - FulFilled", state, action);

      state.pending = false;
      state.login = true;

      handleResponse(action);

      return state;
    },
    // Verify Otp status
    [`${verifyOtp.pending}`]: (state, action) => {
      console.log("Verify Otp - Pending", state, action);
      state.pending = true;
      return state;
    },
    [`${verifyOtp.rejected}`]: (state, action) => {
      console.log("Verify Otp  - Rejected", state, action);
      state.pending = false;
      state.error = action.error.message;
      handleResponse(action);
      return state;
    },
    [`${verifyOtp.fulfilled}`]: (state, action) => {
      console.log("Verify Otp  - FulFilled", state, action);
      const { payload } = action;
      state.pending = false;

      handleHotSecretData(payload);

      handleResponse(action);
      return state;
    },
    // Verify Google Login status
    [`${verifyGoogleLogin.pending}`]: (state, action) => {
    //   console.log("Verify Google Login - Pending", state, action);
      state.pending = true;
      return state;
    },
    [`${verifyGoogleLogin.rejected}`]: (state, action) => {
      console.log("Verify Google Login - Rejected", state, action);
      state.pending = false;
      state.error = action.error.message;
      handleResponse(action);
      return state;
    },
    [`${verifyGoogleLogin.fulfilled}`]: (state, action) => {
      console.log("Verify Google Login - FulFilled", state, action);
      const { payload } = action;
      state.pending = false;

      handleHotSecretData(payload);

      handleResponse(action);
      return state;
    },
    // Signout User
    [`${signOutUser.pending}`]: (state, action) => {
      console.log("Signout User - Pending", state, action);
      state.pending = true;
      return state;
    },
    [`${signOutUser.rejected}`]: (state, action) => {
      console.log("Signout User - Rejected", state, action);
      state.pending = false;
      state.error = action.error.message;
      handleResponse(action);
      return state;
    },
    [`${signOutUser.fulfilled}`]: (state, action) => {
      console.log("Signout User - FulFilled", state, action);
      const { payload } = action;
      state.pending = false;

      handleResponse(action);
      return state;
    },
    [`${getGraphProfile.pending}`]: (state, action) => {
      state.pending = true;
      return state;
    },
    [`${getGraphProfile.rejected}`]: (state, action) => {
      state.pending = false;
      state.error = action.error.message;
      return state;
    },
    [`${getGraphProfile.fulfilled}`]: (state, action) => {
      const { payload } = action;
      state.pending = false;

      return state;
    },
  },
});

export default authSlice.reducer;

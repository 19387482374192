import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthHelper from "../../Utility/AuthHelper";

const ProtectedRoute = ({
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (AuthHelper.isAuthenticated()) {
                    return (
                        // <Layout>
                        <Component {...props} />
                        // </Layout>
                    );
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

export default ProtectedRoute;
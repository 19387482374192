import { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactLoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

interface LoadingOverlay {
  active?: boolean;
  children?: any;
  text?: string;
  isOpen: boolean;
}



export const LoadingOverlay = ({
  pending = false,
  isOpen=false,
  children,
  text = "Loading...",
}) => {
  const [active, setActive] = useState(pending);

  useEffect(() => {
    setActive(pending);
  }, [pending]);

  return (
    <ReactLoadingOverlay
      classNamePrefix="growindigo_"
      active={active&& !isOpen}
      spinner={<BounceLoader color="#27878E" />}
      text={text}
    >
      {children}
    </ReactLoadingOverlay>
  );
};

const mapStateToProps = (state: any) => {
  return {
    pending:
      state?.auth?.pending ||
      state?.privileges?.pending ||
      state?.roles?.pending ||
      state?.seller?.pending ||
      state.users?.pending,
  };
};

export default connect(mapStateToProps)(LoadingOverlay);

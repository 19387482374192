import { Button } from "@material-ui/core";
import StorageHelper from "../../Utility/StorageHelper";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Component, useState } from "react";
import { connect } from "react-redux";
import {
  getGraphProfile,
  signOutUser,
} from "../../Libraries/redux/reducers/Auth/reducer";
import { unwrapResult } from "@reduxjs/toolkit";


function MicrosoftLogin(Iprops: any) {
  const { instance, accounts } = useMsal();
  // const [graphData, setGraphData] = useState(null);

  const onSuccess = (data: any) => {
    let responseObj: any = {
      name: data?.displayName,
      email: data?.mail,
    };

    Iprops.handleLogin({ google_login: responseObj });
  };

  

  const initiateLogin = async (_loginRequest) => {
    try {
      StorageHelper.set("initiated-microsoft", "1");
      console.log(" Initiating Microsoft login...");
      console.log(" Initiating Microsoft login2...");
      const res = await instance.loginPopup({
        ..._loginRequest,
        prompt: "select_account",
        usePopup: true,
        pollIntervalMilliseconds:0 
      });

      console.log("response",res)
      if (res) {
        console.log("Microsoft login successful. Setting active account...");

        // Set the active account
        // await instance.setActiveAccount(res.account);

        console.log("Active account set. Acquiring token silently...");

        // Acquire token silently
        const response = await instance.acquireTokenSilent({
          ..._loginRequest,
          account: res.account ?? accounts[0], // If no account provided, fallback to the first account in the list
        });

        console.log("Token acquired successfully. Fetching Graph profile...");

        // Fetch Graph profile using acquired token
        const graphProfile = await Iprops.getGraphProfile({
          token: response.accessToken,
        })
          .then(unwrapResult)
          .then(async (_res) => await _res);
        if (graphProfile) {
          console.log("Graph profile fetched successfully.");
          // setGraphData(graphProfile);
          onSuccess(graphProfile);
        } else {
          Iprops.signOutUser();
        }
      }
    } catch (error) {
      console.error("An error occurred during Microsoft login:", error);
    }
  };

  //   const initiateLogin = async (_loginRequest: any) => {
  //     try {
  //       StorageHelper.set("initiated-microsoft", "1");
  //       console.log("hi1");
  //       await instance
  //         .loginPopup({ ...loginRequest, prompt: "select_account" })
  //         .then(async (res) => {
  //           console.log("hi2");
  //           await instance.setActiveAccount(res.account);
  //           await instance
  //             .acquireTokenSilent({
  //               ...loginRequest,
  //               account: res.account ?? accounts[0],
  //             })
  //             .then(async (response) => {
  //               await Iprops.getGraphProfile({ token: response.accessToken })
  //                 .then(unwrapResult)
  //                 .then(async (_response) => {
  //                   await setGraphData(_response);
  //                 //   console.log(_response)
  //                   onSuccess(_response);
  //                 });
  //             });
  //         })
  //         .catch((error) => {
  //           console.error({ error });
  //         });
  //     } catch (error) {
  //       console.error({ error });
  //     }
  //   };

  const onSubmit = () => {
    console.log("Initiat Login")
    initiateLogin(loginRequest);
  };



  return (
    <Button
      onClick={onSubmit}
      fullWidth
      style={{ backgroundColor: "#EA4335", color: "white", marginTop: "15px" }}
    >
      Continue with Microsoft
    </Button>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  getGraphProfile: (payload: any) => dispatch(getGraphProfile(payload)),
  signOutUser: (payload: any) => dispatch(signOutUser(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftLogin);

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
// import './index.css';
import "./styles/tailwind.css";

const msalInstance = new PublicClientApplication(msalConfig);

const AppProvider = () => (
  <MsalProvider instance={msalInstance}>
      <App />
  </MsalProvider>
);

if (window.location.hash !== ''){
  console.log("hash found" + window.location.hash);
}
else{
ReactDOM.render(
  <React.StrictMode>
     <MsalProvider instance={msalInstance}>
     <AppProvider />
     </MsalProvider>
     </React.StrictMode>,
  document.getElementById("root")
);}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

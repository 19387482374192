import Filters from "./Filters";
import SampleFarmerList from "./SampleFarmerList";
import Download_Icon from "../../assets/images/download_icon.svg";
import AssignModal from "./AssignModal";
import { useEffect, useState } from "react";
import BulkUpload from "./BulkUpload";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import StorageHelper from "../../Utility/StorageHelper";
import { fieldActivityPageNumber, fieldActivityPageSize } from "../../Utility/constants";

export default function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSampledFarmers, setSelectedSampledFarmers] = useState([]);
  const [componentShown, setComponentShown] = useState("");
  const [activities, setActivities] = useState([]);
  const [totalAcitivityCount, setTotalAcitivityCount] = useState(0);
  const [filterParams, setfilterParams] = useState(
    {
      page_no: fieldActivityPageNumber,
      page_size: fieldActivityPageSize,
      year: 2024,
      status: "Y",
      states: [],
      districts: [],
      search_text: "",
    }
  );
  
  const handleOpenAssignModal = (open) => {
    try {
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseAssignModal = () => {
    try {
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOnBulkUpload = () => {
    try {
      setComponentShown("bulkUpload");
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => {
    try {
      setComponentShown("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilter = (objectParams) => {
    try {
      handleSetFilter(objectParams);
      handleFetchFieldActivity(objectParams);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetFilter = (objectParams)=>{
    try {
      setfilterParams(objectParams);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleFetchFieldActivity(filterParams);
  }, []);

  const handleFetchFieldActivity = async (params) => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/getFieldActivity",
        authHeader,
        params
      );
      if (response?.success) {
        setActivities(response?.data?.fieldActivityMaster);
        setTotalAcitivityCount(response?.data?.totalCount)
        return response?.data;
      } else {
        setActivities([]);
        setTotalAcitivityCount(0)
      }
    } catch (error) {
      console.error(error);
      setActivities([]);
    }
  };

  if (componentShown === "") {
    return (
      <>
        <div className="p-3 flex">
          <h3 className="ml-2 mt-2 text-2xl font-bold">Sampled farmer</h3>
          <button
            type="button"
            className="flex ml-auto gap-2 p-2 px-3 justify-center items-center border-2 rounded-md bg-white border-[#009CA7]"
            onClick={handleClickOnBulkUpload}
          >
            <img src={Download_Icon} alt="Search_Icon" />
            <span className="text-[#009CA7]">Upload</span>
          </button>
        </div>
        <hr />
        <Filters handleFilter={handleFilter} />
        <SampleFarmerList
          handleOpenAssignModal={handleOpenAssignModal}
          setSelectedSampledFarmers={setSelectedSampledFarmers}
          arrActivities={activities}
          handleFilter={handleFilter}
          filterParams={filterParams}
          totalCount={totalAcitivityCount}
        />
        <AssignModal
          open={isOpen}
          handleCloseAssignModal={handleCloseAssignModal}
          data={selectedSampledFarmers}
        />
      </>
    );
  } else if (componentShown === "bulkUpload") {
    return <BulkUpload handleBack={handleBack} />;
  }
}

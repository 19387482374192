import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { DatePicker } from "@material-ui/pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Adduserlocationdetails from "./Adduserlocationdetails";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import StorageHelper from "../../Utility/StorageHelper";
import Calender from "../../assets/images/calenderIcon.svg";
import AlertDialog from "./AlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import LocationDetails from "./LocationDetails";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";

const useStyles = makeStyles({
  smallerCheckbox: {
    transform: "scale(0.8)",
  },
});

interface AddUsersProps {
  closeDialog: () => void;
  fromEditUser: boolean;
  editUserData: any;
}

const AddUsers: React.FC<AddUsersProps> = (props) => {
  const { closeDialog, fromEditUser, editUserData } = props;
  const classes = useStyles();
  const [kaMobile, setKaMobile] = useState("");
  const [roles, setRoles] = useState([]);
  const [kaUserName, setKaUserName] = useState("");
  const [kaJoiningDate, setKaJoiningDate] = useState<Date | null>(null);
  const [exitDate, setExitDate] = useState<Date | null>(null);
  const [kaUserEmail, setKaUserEmail] = useState("");
  const [userLocation, setUserLocation] = useState<any>({
    zone: [],
    state: [],
    territory: [],
    district: [],
  });
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isNameTouched, setIsNameTouched] = useState(false);
  const [rmOptions, setRmOptions] = useState([]);
  const [isTestUser, setIsTestUser] = useState(false);
  const [altMobileLogin, setAltMobileLogin] = useState(false);
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [rmdataLoading, setRMDataLoading] = useState(false);

  const [selectedPartnerOption, setSelectedPartnerOption] = useState<any>({
    label: "",
    value: "",
  });

  const [selectedRoleOption, setSelectedRoleOption] = useState<any>({
    label: "",
    value: "",
  });

  const [selectedStatus, setSelectedStatus] = useState("N");

  const [selectedRM, setSelectedRM] = useState<any>({
    label: "",
    value: "",
  });

  const statusOptions = [
    { label: "Active", value: "Y" },
    { label: "In-Active", value: "N" },
  ];

  const [errorStates, setErrorStates] = useState({
    kaUserName: kaUserName === "" ? true : false,
    kaUserEmail: kaUserEmail === "" ? true : false,
    kaMobile: kaMobile === "" ? true : false,
    selectedRoleId: selectedRoleOption.value === "" ? true : false,
    kaPartnerId: selectedPartnerOption.value === "" ? true : false,
    kaJoiningDate: kaJoiningDate === null ? true : false,

    zone: userLocation?.zone?.length === 0 ? true : false,
    state: userLocation?.state?.length === 0 ? true : false,
    territory: userLocation?.territory?.length === 0 ? true : false,
    district: userLocation?.district?.length === 0 ? true : false,
    rm: selectedRM?.value === ("" || undefined) ? true : false,
    isValidAltMobile: false,
  });

  const handleFindPartner = (partnerId) => {
    try {
      if (partnerOptions?.length) {
        let filteredPartner = partnerOptions?.find((partner: any) => {
          if (partner.partnerId === partnerId) {
            return partner;
          }
        });
        return filteredPartner;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFindRoleById = (roleId) => {
    try {
      if (roles?.length) {
        let filteredRole = roles?.find((role: any) => {
          if (role.roleId === roleId) {
            return role;
          }
        });
        return filteredRole;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFindRm = (rmId) => {
    try {
      if (rmId) {
        let filteredRm = rmOptions?.find((rm: any) => {
          if (rm.rmId === rmId) {
            return rm;
          }
        });

        return filteredRm;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (fromEditUser) {
      const user = editUserData;
      setKaUserName(user?.name);
      setKaUserEmail(user?.emailId);
      setSelectedStatus(user?.rolesForKa[0]?.status === true ? "Y" : "N");
      setExitDate(user?.exitDate);
      setKaJoiningDate(user?.rolesForKa[0]?.joinedDate);
      setExitDate(user?.rolesForKa[0]?.exitDate);
      setKaMobile(user?.mobile);
      setAlternateMobileNumber(user?.altMobile);
      setAltMobileLogin(user?.altMobileLogin);
      setIsTestUser(user?.isTestUser);
      const selectedRole: any = handleFindRoleById(user?.rolesForKa[0]?.roleId);

      if (selectedRole) {
        setSelectedRoleOption({
          label: selectedRole?.name,
          value: selectedRole?.roleId,
        });
      }
      const seletedPartner: any = handleFindPartner(user?.partnerId);
      if (seletedPartner) {
        setSelectedPartnerOption({
          label: seletedPartner?.name,
          value: seletedPartner.partnerId,
        });
      }
    }
  }, [fromEditUser, partnerOptions, roles]);
  useEffect(() => {
    if (
      fromEditUser &&
      selectedRoleOption?.label === editUserData?.rolesForKa[0]?.roleName
    ) {
      const user = editUserData;

      const filteredRM: any = handleFindRm(
        user?.rolesForKa[0]?.reportingManager
      );

      if (filteredRM) {
        setSelectedRM({
          label: filteredRM?.label,
          value: filteredRM?.value,
        });
      }
    }
  }, [rmOptions, editUserData]);

  useEffect(() => {
    setErrorStates({
      kaUserName:
        kaUserName.trim() === "" ||
        kaUserName?.length > 50 ||
        /^\.*$/.test(kaUserName)
          ? true
          : false,
      kaUserEmail: kaUserEmail === "" ? true : false,
      kaMobile: kaMobile === "" || kaMobile?.length < 10 ? true : false,
      selectedRoleId: selectedRoleOption?.value === "" ? true : false,
      kaPartnerId: selectedPartnerOption?.value === "" ? true : false,
      kaJoiningDate: kaJoiningDate === null ? true : false,

      zone: userLocation?.zone?.length === 0 ? true : false,
      state: userLocation?.state?.length === 0 ? true : false,
      territory: userLocation?.territory?.length === 0 ? true : false,
      district: userLocation?.district?.length === 0 ? true : false,
      rm: selectedRM?.value === ("" || undefined) ? true : false,
      isValidAltMobile:
        alternateMobileNumber !== "" && alternateMobileNumber?.length < 10
          ? true
          : false,
    });
  }, [
    kaUserName,
    kaUserEmail,
    kaMobile,
    selectedRoleOption,
    selectedPartnerOption,
    exitDate,
    kaJoiningDate,
    userLocation,
    selectedRM,
    alternateMobileNumber,
  ]);

  const fetchRoles = async () => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        return await FnAuthenticatedRequest(
          "GET",
          "api/getRoles",
          authHeader,
          ""
        );
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fnGetPartnerList = async () => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        return await FnAuthenticatedRequest(
          "GET",
          "api/getPartnerKa",
          authHeader,
          ""
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fnGetPartnerAndRoles = async () => {
    setDataLoading(true);

    const partnerListData: any = await fnGetPartnerList();
    if (partnerListData?.success && partnerListData?.data?.length > 0) {
      setPartnerOptions(partnerListData?.data);
      setDataLoading(false);
    }

    const rolesData: any = await fetchRoles();
    if (rolesData?.success && rolesData?.data?.length > 0) {
      const sortedRolesData = rolesData?.data?.sort((a, b) =>
        (a?.name?.toUpperCase() ?? "") < (b?.name?.toUpperCase() ?? "")
          ? -1
          : (a?.name?.toUpperCase() ?? "") > (b?.name?.toUpperCase() ?? "")
          ? 1
          : 0
      );
      setRoles(sortedRolesData);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    fnGetPartnerAndRoles();
  }, []);

  const fnGetReportingManager = async () => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const payload = {
        roleName: selectedRoleOption.label,
      };
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/getRoleWiseUser",
        authHeader,
        payload
      );

      if (response?.success) {
        const data: any = response?.data;
        const stateIDs = userLocation.state.map((state) => state.state_id);
        const zoneIDs = userLocation.zone.map((zone) => zone.zone_id);
        const territoryIDs = userLocation.territory.map(
          (territory) => territory.territory_id
        );

        let filterData;

        if (selectedRoleOption.label == "KA") {
          filterData = data.filter(
            (user) =>
              (user.territoryMap &&
                user.territoryMap.state.some((state) =>
                  stateIDs.includes(state.stateId)
                ) &&
                user.territoryMap.territory.some((territory) =>
                  territoryIDs.includes(territory.territoryId)
                )) ||
              (user.territoryMap &&
                user.territoryMap.state.some((state) =>
                  stateIDs.includes(state.stateId)
                ))
          );
        } else if (selectedRoleOption.label === "RBM") {
          filterData = data.filter(
            (user) =>
              user.rolesForKa.some(
                (role) => role?.roleName === "NM" || "ZBM"
              ) ||
              (user.territoryMap &&
                user.territoryMap.zone.some((zone) =>
                  zoneIDs.includes(zone.zoneId)
                ))
          );
        } else if (selectedRoleOption.label === "TBM") {
          filterData = data.filter(
            (user) =>
              user.rolesForKa.some(
                (role) => role?.roleName === "ZBM" || "RBM"
              ) ||
              (user.territoryMap &&
                user.territoryMap.state.some((state) =>
                  stateIDs.includes(state.stateId)
                ))
          );
        } else {
          filterData = data;
        }

        let rmOptions = filterData.map((rm) => {
          if (rm?.mobile != kaMobile) {
            let rmObject = {
              label: rm?.name?.toUpperCase(),
              value: rm?.userId,
              rmId: rm?.userId,
              rmName: rm?.name?.toUpperCase(),
              mobile: rm?.mobile,
            };
            return rmObject;
          }
        });
        setRmOptions(rmOptions);
      } else {
        setDataLoading(false);
        console.error("Error fetching reporting manager:", response.data);
      }
    } catch (error) {
      console.error("Error fetching reporting manager:", error);
    }
  };

  const handleRoleChange = (e: any) => {
    try {
      handleSetUserLocations([], [], [], []);
      const selectedRole: any = roles.find(
        (role: any) => role.name === e.target.value
      );
      setSelectedRoleOption({
        label: selectedRole?.name,
        value: selectedRole?.roleId,
      });
      setSelectedRM({
        label: "",
        value: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleRMChange = (e: any) => {
    try {
      const selectedRM: any = rmOptions?.find(
        (rm: any) => rm?.rmName === e.target.value
      );
      setSelectedRM({
        label: selectedRM?.rmName,
        value: selectedRM?.rmId,
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    let locationCondition = false;
    if (selectedRoleOption?.label == "ZBM") {
      locationCondition =
        userLocation?.state?.length > 0 || userLocation?.zone?.length > 0;
    } else {
      locationCondition =
        userLocation?.state?.length > 0 && userLocation?.zone?.length > 0;
    }
    if (
      (selectedRoleOption?.label !== "NM" ||
        selectedRoleOption?.label !== "") &&
      locationCondition
    ) {
      // setSelectedRoleOption([]);
      if (userLocation?.zone?.length > 0) {
        fnGetReportingManager();
      }

      setSelectedRM([]);
    }

    setRmOptions([]);
  }, [selectedRoleOption, userLocation]);

  useEffect(() => {
    let locationCondition = false;
    if (userLocation?.length > 0) {
      if (selectedRoleOption?.label == "ZBM") {
        locationCondition =
          userLocation?.state?.length > 0 || userLocation?.zone?.length > 0;
      } else {
        locationCondition =
          userLocation?.state?.length > 0 && userLocation?.zone?.length > 0;
      }
      if (
        fromEditUser &&
        selectedRoleOption?.label == editUserData?.rolesForKa[0]?.roleName
      ) {
        setRMDataLoading(true);
        if (locationCondition) {
          setTimeout(() => {
            setRMDataLoading(false);
          }, 2000);
        }
      }
    }
  }, [userLocation, selectedPartnerOption?.label, rmOptions]);

  const handlePartnerChange = (e: any) => {
    try {
      const seletedPartner: any = partnerOptions.find(
        (partner: any) => partner.name === e.target.value
      );
      setSelectedPartnerOption({
        label: seletedPartner?.name,
        value: seletedPartner?.partnerId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetUserLocations = (
    arrZone: any,
    arrStates: any,
    arrTerritories: any,
    arrDistricts: any
  ) => {
    try {
      const userLocationObject: any = {
        zone: arrZone,
        state: arrStates,
        territory: arrTerritories,
        district: arrDistricts,
      };
      setUserLocation(userLocationObject);
    } catch (error) {
      console.error(error);
    }
  };
  const isValidEmail = (email: string): boolean => {
    const emailRegex: RegExp =
      /^$|^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  const isValidName = (name: string): boolean => {
    const nameRegex: RegExp = /^$|^(?!.*\.{2})[a-zA-Z.\s]+$/;
    return nameRegex.test(name);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    try {
      if (selectedRoleOption?.label === "NM") {
        setErrorStates({
          ...errorStates,
          zone: false,
          state: false,
          territory: false,
          district: false,
          rm: false,
        });
      } else if (selectedRoleOption?.label === "ZBM") {
        setErrorStates({
          ...errorStates,
          zone: userLocation.zone?.length > 0 ? false : true,
          state: false,
          territory: false,
          district: false,
        });
      } else if (selectedRoleOption?.label === "RBM") {
        setErrorStates({
          ...errorStates,
          zone: userLocation.zone?.length > 0 ? false : true,
          state: userLocation.state?.length > 0 ? false : true,
          territory: false,
          district: false,
        });
      } else if (selectedRoleOption?.label === "TBM") {
        setErrorStates({
          ...errorStates,
          zone: false,
          state: userLocation.state?.length > 0 ? false : true,
          territory: userLocation.territory?.length > 0 ? false : true,
          district: false,
        });
      } else if (selectedRoleOption?.label === "KA") {
        setErrorStates({
          ...errorStates,
          zone: false,
          state: userLocation.state?.length > 0 ? false : true,
          territory: userLocation.territory?.length > 0 ? false : true,
          district: userLocation.district?.length > 0 ? false : true,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    errorStates.kaUserName,
    errorStates.kaMobile,
    errorStates.kaPartnerId,
    errorStates.selectedRoleId,
    errorStates.kaJoiningDate,
    errorStates.zone,
    errorStates.state,
    errorStates.territory,
    errorStates.district,
    errorStates.rm,
    errorStates.isValidAltMobile,
    isLoading,
  ]);

  const handleSwitchTestUser = (event) => {
    setIsTestUser(event.target.checked);
  };

  const handleUseForLogin = (event) => {
    setAltMobileLogin(event.target.checked);
  };

  const fetchUsers = async () => {
    try {
      const authData = JSON.parse(localStorage.getItem("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const params = {
          mobile: [alternateMobileNumber],
        };

        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/checkMobileUser",
          authHeader,
          params
        );

        return response?.success;
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const handleChangeReviewStatus = (e) => {
    try {
      setSelectedStatus(e?.target?.value);
      
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateUser = async (e: any) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const payload = {
        name: kaUserName,
        mobile: kaMobile,
        roleId: selectedRoleOption.value,
        roleName: selectedRoleOption.label,
        partnerId: selectedPartnerOption.value,
        altMobile: alternateMobileNumber,
        altMobileLogin: altMobileLogin,
        email: kaUserEmail,
        territoryMap: userLocation,
        joinedDate: kaJoiningDate,
        createdBy: authData.email,
        reportingManager: selectedRM.value,
        isTestUser: isTestUser,
        appCode: "carbon-bo",
        vertical: "carbon",
      };
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/add-ka",
        authHeader,
        payload
      );
      toast.dismiss();
      setIsLoading(true);

      if (response?.success) {
        toast.success("New Entry Added", {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#258750",
            color: "white",
          },
          autoClose: 3000,
          toastId: "customErrorToast",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setIsLoading(false);
        toast.error(response?.error || "Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          toastId: "customErrorToast",
        });
      }
    } catch (error) {
      toast.dismiss();
      setIsLoading(false);
      console.error(error);
      toast.error("Something went wrong !", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        toastId: "customErrorToast",
      });
    }
  };

  const handleUpdateUser = async (e) => {
    setIsLoading(true);

    e.preventDefault();
    try {
      let altMobileExist = false;
      if (alternateMobileNumber?.length > 0) {
        altMobileExist = await fetchUsers();
      }

      if (!altMobileExist) {
        const roleInput = [
          {
            roleId: selectedRoleOption.value,
            roleName: selectedRoleOption.label,
            joinedDate: kaJoiningDate,
            status: selectedStatus === "Y" ? true : false,
            exitDate: selectedStatus === "N" ? exitDate : null,
            reportingManager: selectedRM.value,
            appCode: "carbon-bo",
            vertical: "carbon",
          },
        ];
        let authData = JSON.parse(StorageHelper.get("auth") || "{}");
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = {
          userId: editUserData?.userId,
          name: kaUserName,
          email: kaUserEmail,
          mobile: kaMobile,
          altMobile: alternateMobileNumber,
          altMobileLogin: altMobileLogin,
          partnerId: selectedPartnerOption.value,
          status: selectedStatus === "Y" ? true : false,
          previousRoleId: editUserData?.rolesForKa[0]?.roleId,
          roles: roleInput,
          territoryMap: userLocation,
          createdBy: authData.email,
          isTestUser: isTestUser,
        };

        const route = "/api/updateCarbonUser/" + editUserData?.userId;

        const response: any = await FnAuthenticatedRequest(
          "POST",
          route,
          authHeader,
          payload
        );

        if (response?.success) {
          //  setIsLoading(true);
          toast.success("Entry updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            style: {
              backgroundColor: "#258750",
              color: "white",
            },
            autoClose: 3000,
            toastId: "customErrorToast",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setIsLoading(false);
          toast.error(response?.error || "Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            toastId: "customErrorToast",
          });
        }
      } else {
        setIsLoading(false);
        toast.error("Alternate mobile number is already registered  !", {
          position: toast.POSITION.TOP_CENTER,
          style: {
            // backgroundColor: "#258750",
            color: "white",
          },
          autoClose: 3000,
          toastId: "customErrorToast",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="container p-5 min-w-full">
      <div className="block sm:flex items-center">
        <ArrowBackIcon
          onClick={() => {
            if (
              kaUserName ||
              kaMobile ||
              selectedRoleOption?.value ||
              kaUserEmail ||
              selectedRoleOption?.value ||
              kaJoiningDate ||
              userLocation?.state?.length ||
              userLocation.district?.length
            ) {
              handleOpen();
            } else {
              closeDialog();
            }
          }}
          // onClick={closeDialog}
          className="cursor-pointer transition-all hover:text-[#009CA7] active:text-[#007a80]"
        />
        <h1 className="text-xl font-bold px-3 py-3">
          {fromEditUser === true ? "Edit" : "Add"} Kisan Advisor
        </h1>
        <div className="text-gray-500 px-3 text-center ml-auto border border-gray-300 rounded-xl bg-white select-none">
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isTestUser}
                onChange={handleSwitchTestUser}
              />
            }
            label="This is test user"
          />
        </div>
      </div>
      <hr className="mt-4 border border-gray-200" />
      {!dataLoading ? (
        <>
          <form
            action=""
            onSubmit={
              fromEditUser === true ? handleUpdateUser : handleCreateUser
            }
          >
            <div className="bg-white rounded-xl mt-5 p-5">
              <h3 className="font-semibold text-lg">Basic Details</h3>
              <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-3 gap-5">
                <TextField
                  id="full-name"
                  label="Full Name"
                  variant="outlined"
                  value={kaUserName}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (
                      inputValue.startsWith(" ") ||
                      inputValue.startsWith(".")
                    ) {
                      inputValue = inputValue.trimStart();
                    }
                    inputValue = inputValue.replace(
                      /[^a-zA-Z\s.]+[a-zA-Z\s.]*[\s.]?$/,
                      ""
                    );
                    setKaUserName(inputValue.slice(0, 30));
                  }}
                  required
                  error={!isValidName(kaUserName) && isNameTouched}
                  helperText={
                    !isValidName(kaUserName) &&
                    isNameTouched &&
                    "Enter valid name !"
                  }
                  onBlur={() => setIsNameTouched(true)}
                />

                <DatePicker
                  id="joining-date"
                  label="Joining Date"
                  inputVariant="outlined"
                  required
                  value={kaJoiningDate}
                  onChange={(date: Date | null) => setKaJoiningDate(date)}
                  format="dd/MM/yyyy"
                  autoOk
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <img src={Calender} alt="ka_icon" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={kaUserEmail}
                  onChange={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue.startsWith(" ")) {
                      inputValue = inputValue.trimStart();
                    }
                    inputValue = inputValue.replace(/[^a-zA-Z0-9@._-]+$/, "");
                    setKaUserEmail(inputValue);
                  }}
                  error={!isValidEmail(kaUserEmail) && isEmailTouched}
                  helperText={
                    !isValidEmail(kaUserEmail) &&
                    isEmailTouched &&
                    "Enter valid email !"
                  }
                  onBlur={() => setIsEmailTouched(true)}
                />
                <TextField
                  id="primary-mobile"
                  label="Primary Mobile Number"
                  variant="outlined"
                  value={kaMobile}
                  disabled={fromEditUser}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, "");
                    if (inputValue !== "0000000000") {
                      setKaMobile(inputValue);
                    }
                  }}
                  error={
                    (kaMobile?.length !== 10 && kaMobile?.length !== 0) ||
                    kaMobile === "0000000000"
                  }
                  required
                  inputProps={{ maxLength: 10 }}
                />

                <div className="flex flex-col">
                  <TextField
                    id="alternate-mobile"
                    label="Alternate Mobile Number"
                    variant="outlined"
                    value={alternateMobileNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/\D/g, "");
                      if (
                        (inputValue !== "0000000000" &&
                          inputValue !== kaMobile) ||
                        inputValue === ""
                      ) {
                        setAlternateMobileNumber(inputValue);
                      }
                    }}
                    error={
                      (alternateMobileNumber?.length !== 10 &&
                        !fromEditUser &&
                        alternateMobileNumber?.length !== 0) ||
                      (alternateMobileNumber === "0000000000" &&
                        alternateMobileNumber === kaMobile)
                    }
                    inputProps={{ maxLength: 10 }}
                  />
                  {fromEditUser && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={altMobileLogin}
                          className={classes.smallerCheckbox}
                          onChange={handleUseForLogin}
                          disabled={
                            alternateMobileNumber === "" ||
                            alternateMobileNumber === null ||
                            alternateMobileNumber?.length < 10
                              ? true
                              : false
                          }
                        />
                      }
                      label="Use for login"
                    />
                  )}
                </div>

                <FormControl variant="outlined" required>
                  <InputLabel id="partner-label">Partner Name</InputLabel>
                  <Select
                    labelId="partner-label"
                    id="partner"
                    label="Partner Name"
                    required
                    onChange={handlePartnerChange}
                    value={selectedPartnerOption.label}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      PaperProps: {
                        style: {
                          width: "auto",
                          maxHeight: "calc(100vh - 200px)",
                        },
                      },
                    }}
                  >
                    {partnerOptions?.map((partner: any, index) => (
                      <MenuItem key={index} value={partner.name}>
                        {partner.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" required>
                  <InputLabel id="role-label">Role</InputLabel>
                  <Select
                    labelId="role-label"
                    id="role"
                    label="Role"
                    required
                    value={selectedRoleOption.label}
                    onChange={handleRoleChange}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      PaperProps: {
                        style: {
                          width: "auto",
                          maxHeight: "calc(100vh - 200px)",
                        },
                      },
                    }}
                  >
                    {roles.map((role: any) => {
                      if (
                        role.name === "KA" ||
                        role.name === "NM" ||
                        role.name === "ZBM" ||
                        role.name === "TBM" ||
                        role.name === "RBM"
                      ) {
                        return (
                          <MenuItem key={role.roleId} value={role.name}>
                            {role.name}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
            {selectedRoleOption.label !== "NM" &&
              selectedRoleOption.label !== "" && (
                <div className="bg-white rounded-xl mt-5 p-5">
                  <h3 className="font-semibold text-lg">Location Details</h3>

                  <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-3 gap-5">
                    <div className="col-span-3">
                      <Adduserlocationdetails
                        setLocations={handleSetUserLocations}
                        selectedRole={selectedRoleOption?.label}
                        fromEditUser={fromEditUser}
                        territoryData={editUserData?.territoryMap}
                        rolesData={editUserData?.rolesForKa}
                      />
                    </div>
                  </div>
                </div>
              )}

            {selectedRoleOption.label !== "NM" &&
              selectedRoleOption.label !== "" && (
                <div className="bg-white rounded-xl mt-5 p-5">
                  <h3 className="font-semibold text-lg">Other Details</h3>
                  {!rmdataLoading ? (
                    <>
                      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-3 gap-5">
                        <FormControl
                          variant="outlined"
                          required
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="rm-label">
                            Reporting Manager
                          </InputLabel>
                          <Select
                            id="rm"
                            labelId="rm-label"
                            label="Reporting Manager"
                            required
                            onChange={handleRMChange}
                            value={selectedRM.label}
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              PaperProps: {
                                style: {
                                  width: "auto",
                                },
                              },
                            }}
                          >
                            {rmOptions.map((rm: any, index) => {
                              return (
                                <MenuItem key={index} value={rm?.rmName}>
                                  {rm?.rmName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>
                    </>
                  ) : (
                    <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-3 gap-5">
                      <FormControl
                        variant="outlined"
                        required
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <InputLabel id="rm-label">Reporting Manager</InputLabel>
                        <Select
                          id="rm"
                          labelId="rm-label"
                          label="Reporting Manager"
                          required
                          onChange={handleRMChange}
                          value={selectedRM.label}
                          disabled
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            PaperProps: {
                              style: {
                                width: "auto",
                              },
                            },
                          }}
                        >
                          {rmOptions.map((rm: any, index) => (
                            <MenuItem key={index} value={rm?.rmName}>
                              {rm?.rmName}
                            </MenuItem>
                          ))}
                        </Select>
                        <ReactLoadingOverlay
                          classNamePrefix="growindigo_"
                          active={true}
                          spinner={<ClipLoader color="#27878E" />}
                          text={""}
                        >
                          {/* Your content here */}
                        </ReactLoadingOverlay>
                      </FormControl>
                    </div>
                  )}
                </div>
              )}
            {fromEditUser && (
              <div className="bg-white rounded-xl mt-5 p-5">
                <h3 className="font-semibold text-lg">Review Status</h3>

                <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-3 gap-5">
                  <FormControl variant="outlined" required>
                    <InputLabel id="role-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      label="Status"
                      required
                      style={{
                        color: selectedStatus === "Y" ? "#2C8B5E" : "#FF0000",
                      }}
                      value={
                        statusOptions?.find(
                          (status: any) => status?.value === selectedStatus
                        )?.value
                      }
                      onChange={handleChangeReviewStatus}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        PaperProps: {
                          style: {
                            width: "auto",
                            maxHeight: "calc(100vh - 200px)",
                          },
                        },
                      }}
                    >
                      {statusOptions?.map((status: any) => {
                        return (
                          <MenuItem
                            key={status?.label}
                            value={status?.value}
                            style={{
                              color:
                                status?.value === "Y" ? "#2C8B5E" : "#FF0000",
                            }}
                          >
                            {status?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  {selectedStatus === "N" && (
                    <DatePicker
                      className=""
                      id="exit-date"
                      label="Exit Date"
                      inputVariant="outlined"
                      required
                      value={selectedStatus === "N" ? exitDate : ""}
                      onChange={(date: Date | null) => setExitDate(date)}
                      format="dd/MM/yyyy"
                      autoOk
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <img src={Calender} alt="ka_icon" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="flex gap-3 my-5 justify-center md:justify-start">
              <button
                type="button"
                className="py-2 px-10 border border-black bg-white rounded font-semibold"
                onClick={() => {
                  if (
                    kaUserName ||
                    kaMobile ||
                    selectedRoleOption?.value ||
                    kaUserEmail ||
                    selectedRoleOption?.value ||
                    kaJoiningDate ||
                    userLocation?.state?.length ||
                    userLocation.district?.length
                  ) {
                    handleOpen();
                  } else {
                    closeDialog();
                  }
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={
                  errorStates.kaUserName ||
                  errorStates.kaMobile ||
                  errorStates.kaPartnerId ||
                  errorStates.selectedRoleId ||
                  errorStates.kaJoiningDate ||
                  errorStates.zone ||
                  errorStates.state ||
                  errorStates.territory ||
                  errorStates.district ||
                  errorStates.rm ||
                  errorStates.isValidAltMobile ||
                  ((selectedStatus === "N" && exitDate) === null &&
                    fromEditUser) ||
                  isLoading ||
                  rmdataLoading
                }
                className={`py-2 px-10 ${
                  errorStates.kaUserName ||
                  errorStates.kaMobile ||
                  errorStates.kaPartnerId ||
                  errorStates.selectedRoleId ||
                  errorStates.kaJoiningDate ||
                  errorStates.zone ||
                  errorStates.state ||
                  errorStates.territory ||
                  errorStates.district ||
                  errorStates.rm ||
                  errorStates.isValidAltMobile ||
                  ((selectedStatus === "N" && exitDate) === null &&
                    fromEditUser) ||
                  isLoading ||
                  rmdataLoading
                    ? `text-white bg-gray-400`
                    : `text-white bg-[#009CA7]`
                } rounded font-semibold`}
              >
                {fromEditUser === true ? "Update" : "Create"}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <ReactLoadingOverlay
            classNamePrefix="growindigo_"
            active={true}
            spinner={<ClipLoader color="#27878E" />}
            text={""}
          >
            {/* Your content here */}
          </ReactLoadingOverlay>
        </>
      )}
      <AlertDialog
        closeDialog={closeDialog}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default AddUsers;
